import { useContext, createContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { LABELS } from '../queries/labels';
import { useUser } from './UserProvider';
import { labelTranslationsFallback } from '../utils/labelTranslationsFallback';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';
import { addDefaultBreadcrumb } from '../utils/elasticApmHeler';

const LabelsContext = createContext();

const labelsReducer = (labelsObject) => {
  if (!labelsObject) return {};

  addDefaultBreadcrumb('Reducing labels', 'LabelsProvider.reducer');
  return Object.keys(labelsObject).reduce((acc, next) => {
    acc[next] = labelsObject[next].reduce((acc, next) => {
      acc[next.id] = next.text;
      return acc;
    }, {});
    return acc;
  }, {});
};

const LabelsProvider = ({ children }) => {
  const { isUserLoading, isLoggedIn } = useUser();
  const {
    data: labelsData,
    loading: labelsLoading,
    error: labelsError,
  } = useQuery(LABELS, {
    skip: isUserLoading || !isLoggedIn,
  });
  let labelsReduced = useMemo(() => labelsReducer(labelsData), [labelsData]);

  if (labelsError) {
    const {
      labels: { svy_snackbar_error_header },
    } = labelTranslationsFallback;
    generalApiErrorHandler({
      error: labelsError,
      snackbarHeader: svy_snackbar_error_header,
      snackbarMessage: 'Only English labels are currently available',
    });
  }

  return (
    <LabelsContext.Provider
      value={{
        labelsLoading,
        labels: labelsReduced.getLabels || labelTranslationsFallback.labels,
        richTextLabels:
          labelsReduced.getRichTextLabels ||
          labelTranslationsFallback.richTextLabels,
      }}
    >
      {children}
    </LabelsContext.Provider>
  );
};

const useLabels = () => {
  const context = useContext(LabelsContext);
  if (context === undefined) {
    throw new Error('useLabels must be used within a LabelsProvider');
  }
  return context;
};

LabelsProvider.propTypes = {
  children: PropTypes.node,
};

export { LabelsProvider, LabelsContext, labelsReducer, useLabels };
