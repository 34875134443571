import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CheckTickBold, MinusBold } from '@lego/klik-icons-react';
import { spacing, font, colors } from '../../config';
import { useLabels } from '../../providers/LabelsProvider';
import { ImageModal } from './modals';
import { UploadedImageThumbnailList } from './UploadedImageThumbnailList';
import { QuestionDescription } from './QuestionDescription';

const questionContentWrapperStyle = css({
  position: 'relative',
  color: colors.neutralColors.slateBase,
  paddingBottom: spacing.size12,
});

const headerWrapperStyle = css({
  display: 'flex',
});

const headerStyle = css({
  fontSize: font.size.size2,
  marginTop: spacing.size4,
  marginBottom: spacing.size2,
});

const answerListStyle = css({
  listStyle: 'none',
  marginTop: spacing.size5,
  marginBottom: spacing.size8,
  paddingLeft: spacing.size6,
});

const additionalFieldWrapperStyle = css({
  marginBottom: spacing.size6,
});

const additionalFieldHeaderStyle = css({
  fontWeight: font.weight.regular,
  fontSize: font.size.size05,
});

const answerListItemIconStyle = (isSelected) =>
  css({
    width: 14,
    marginRight: spacing.size2,
    color: isSelected
      ? colors.utilityColors.success.base
      : colors.neutralColors.slateBase,
    flexShrink: 0,
  });

const answerListItemStyle = (isSelected) =>
  css({
    fontSize: font.size.size0,
    display: 'flex',
    marginTop: spacing.size3,
    color: isSelected
      ? colors.neutralColors.slate900
      : colors.neutralColors.slateBase,
    fontWeight: isSelected ? font.weight.medium : font.weight.regular,
    whiteSpace: 'pre-wrap',
  });

const answerOptionScoreStyle = css({
  fontSize: font.size.sizeM1,
  fontWeight: font.weight.bold,
  marginLeft: spacing.size2,
});

const commentStyle = css({
  display: 'block',
  fontSize: font.size.size05,
  fontStyle: 'italic',
  color: colors.neutralColors.slateBase,
  padding: `0 ${spacing.size6}px`,
});

const QuestionReview = ({ question, answer, uploadImageKeys }) => {
  const { subHeader, conditions, answerOptions, explanation } = question;
  const {
    labels: { svy_additional_comments, svy_attachments },
  } = useLabels();

  const [imageModal, setImageModal] = useState({
    isOpen: false,
    imageId: '',
  });

  const closeImageModal = () => {
    setImageModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  return (
    <div data-testid="questionReview">
      {imageModal.isOpen && (
        <ImageModal
          imageId={imageModal.imageId}
          closeImageModal={closeImageModal}
        />
      )}
      <div css={questionContentWrapperStyle}>
        <div css={headerWrapperStyle}>
          <h2 css={headerStyle}>{subHeader}</h2>
        </div>

        <QuestionDescription
          explanation={explanation}
          conditions={conditions}
        />

        <div>
          <ul css={answerListStyle}>
            {answerOptions.map((answerOption) => {
              const isSelected = answer.selectedAnswer.includes(
                answerOption.answerId
              );

              return (
                <li
                  css={answerListItemStyle(isSelected)}
                  key={answerOption.answerId}
                >
                  {isSelected ? (
                    <CheckTickBold css={answerListItemIconStyle(isSelected)} />
                  ) : (
                    <MinusBold css={answerListItemIconStyle(isSelected)} />
                  )}
                  <span>
                    {`${answerOption.text}`}
                    {answerOption.score !== null && (
                      <span
                        css={answerOptionScoreStyle}
                      >{`(${answerOption.score}%)`}</span>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
          {answer.comment && (
            <div css={additionalFieldWrapperStyle}>
              <h3 css={additionalFieldHeaderStyle}>
                {svy_additional_comments}
              </h3>
              <q css={commentStyle}>{answer.comment}</q>
            </div>
          )}
          {!!uploadImageKeys.length && (
            <div css={additionalFieldWrapperStyle}>
              <h3 css={additionalFieldHeaderStyle}>{svy_attachments}</h3>
              <UploadedImageThumbnailList
                uploadImageKeys={uploadImageKeys}
                isEditable={false}
                imageClickHandler={(imageId) =>
                  setImageModal((prevState) => ({
                    ...prevState,
                    imageId,
                    isOpen: true,
                  }))
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

QuestionReview.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  uploadImageKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { QuestionReview };
