import { gql } from '@apollo/client';

const LABELS = gql`
  query Labels {
    getLabels {
      id
      text
    }
    getRichTextLabels {
      id
      text
    }
  }
`;

const PUBLIC_LABELS = gql`
  query PublicLabels {
    getPublicLabels {
      id
      text
    }
    getPublicRichTextLabels {
      id
      text
    }
  }
`;

export { LABELS, PUBLIC_LABELS };
