import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { spacing, font } from '../../config';

const explanationFieldStyle = css({
  whiteSpace: 'pre-wrap',
  fontSize: font.size.size1,
  fontWeight: font.weight.medium,
  marginTop: spacing.size1,
  marginBottom: spacing.size4,
});

const conditionFieldStyle = css({
  fontSize: font.size.size05,
  whiteSpace: 'pre-wrap',
});

const QuestionDescription = ({ explanation, conditions }) => (
  <Fragment>
    {explanation && <p css={explanationFieldStyle}>{explanation}</p>}
    {conditions && <p css={conditionFieldStyle}>{conditions}</p>}
  </Fragment>
);

QuestionDescription.propTypes = {
  explanation: PropTypes.string,
  conditions: PropTypes.string,
};

export { QuestionDescription };
