import { useReducer, Fragment, useCallback } from 'react';
import { useLocation, useHistory, Redirect, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { useQuery, useMutation } from '@apollo/client';
import { Button } from '@lego/klik-react';
import { useLabels } from '../../providers/LabelsProvider';
import { useUser } from '../../providers/UserProvider';
import { Step, Stepper } from '../stepper';
import { spacing } from '../../config';
import { Question } from '../question/Question';
import {
  surveyReducer,
  SurveyContext,
  resetAction,
  answerSavedAction,
  setUploadedImages,
} from './reducer';
import {
  GET_SURVEY_PAGE,
  UPDATE_ANSWER,
  UPDATE_ALL_ANSWERS,
} from '../../queries/survey';
import { Heading } from '../Heading';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import {
  areAllAnswersComplete,
  enhanceQuestionsWithAnswersAndProof,
  adjustAssessmentsBasedOnFeatureFlags,
} from './helpers';
import { routes } from '../RouteHandler';
import { useFetchImagesFromS3 } from '../../hooks/useFetchImagesFromS3';
import { generalApiErrorHandler } from '../../utils/generalApiErrorHandler';
import { throwErrorWithTransactionName } from '../../utils/elasticApmHeler';
import { withTransaction } from '@elastic/apm-rum-react';

const doneEditingButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: spacing.size8,
});

const Survey = () => {
  const location = useLocation();
  const history = useHistory();
  const { year, version } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const { staticUserVariables } = useUser();
  const isSurveyInEditMode = queryParams.get('mode') === 'edit';

  const {
    labels: {
      svy_end_headline,
      svy_done_editing,
      svy_snackbar_error_header,
      svy_snackbar_error_default_text,
    },
  } = useLabels();
  const [surveyReducerState, dispatch] = useReducer(surveyReducer, {});

  const { loading: isSurveyPageLoading, error: getSurveyPageDataError } =
    useQuery(GET_SURVEY_PAGE, {
      variables: staticUserVariables({ year, version }),
      skip: surveyReducerState?.answers,
      onCompleted: handleSurveyData,
      fetchPolicy: 'network-only',
    });

  const [updateAnswer, { loading: updateAnswerLoading }] = useMutation(
    UPDATE_ANSWER,
    { onCompleted: handleAnswerUpdate }
  );

  const [updateAllAnswers, { loading: updateAllAnswersLoading }] = useMutation(
    UPDATE_ALL_ANSWERS,
    {
      onCompleted: handleAllAnswersUpdateCompleted,
    }
  );

  const shouldFetchImagesFromS3 =
    (surveyReducerState.isSurveyEditable &&
      !surveyReducerState.isSurveyComplete &&
      !!surveyReducerState.uploadId) ||
    (isSurveyInEditMode && !!surveyReducerState.uploadId);

  useFetchImagesFromS3({
    uploadId: surveyReducerState?.uploadId,
    skip: isSurveyPageLoading || !shouldFetchImagesFromS3,
    onCompleted: useCallback(
      (uploadedImagesKeys) => dispatch(setUploadedImages(uploadedImagesKeys)),
      []
    ),
  });

  function handleAnswerUpdate(response) {
    const {
      updateAnswer: { questionId },
    } = response;
    dispatch(answerSavedAction(questionId));
  }

  function handleSurveyData(response) {
    const {
      getSurveyInfo: { surveyState },
    } = response;
    if (!surveyState.isEditable) {
      return history.push(`/${routes.review}`);
    }

    dispatch(resetAction(response));
  }

  const handleAnswerSave = async (questionId) => {
    const answerState = surveyReducerState.answers.find(
      (answer) => answer.questionId === questionId
    );
    try {
      await updateAnswer({
        variables: staticUserVariables({ answer: answerState, year, version }),
      });
    } catch (error) {
      generalApiErrorHandler({
        error,
        snackbarHeader: svy_snackbar_error_header,
        snackbarMessage: svy_snackbar_error_default_text,
      });
    }
  };

  const handleAllAnswersUpdate = async (answers) => {
    try {
      await updateAllAnswers({
        variables: staticUserVariables({ answers, year, version }),
      });
    } catch (error) {
      generalApiErrorHandler({
        error,
        snackbarHeader: svy_snackbar_error_header,
        snackbarMessage: svy_snackbar_error_default_text,
      });
    }
  };

  function handleAllAnswersUpdateCompleted(response) {
    if (response.updateAllAnswers) {
      history.push(`/${routes.review}/${year}/${version}`);
    }
  }

  // Error boundary catches this error
  if (getSurveyPageDataError)
    throwErrorWithTransactionName(getSurveyPageDataError, 'SurveyPage');

  if (
    isSurveyPageLoading ||
    !surveyReducerState.answers?.length ||
    !surveyReducerState.questions?.length ||
    surveyReducerState.isFetchingUploadedImages ||
    updateAllAnswersLoading
  ) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  const isSurveyComplete = areAllAnswersComplete(surveyReducerState.answers);

  if (isSurveyComplete && !isSurveyInEditMode) {
    return <Redirect to={`/${routes.complete}/${year}/${version}`} />;
  }

  const surveyDetailsForFeatureFlag = {
    year: Number(year),
  };

  let enhancedQuestions = enhanceQuestionsWithAnswersAndProof(
    surveyReducerState.questions,
    surveyReducerState.answers,
    surveyReducerState.uploadedImageKeys
  );
  enhancedQuestions = adjustAssessmentsBasedOnFeatureFlags(
    enhancedQuestions,
    surveyDetailsForFeatureFlag
  );

  return (
    <Fragment>
      <Heading type="h1" text={svy_end_headline} />
      <SurveyContext.Provider value={{ dispatch }}>
        <Stepper
          activeStep={surveyReducerState.activeStep}
          stepperState={surveyReducerState}
          shouldHideInactiveStepsContent={!isSurveyInEditMode}
        >
          {enhancedQuestions.map((question, index) => {
            const { questionId, headline, questionImageKeys, isMissingProof } =
              question;
            const questionState = surveyReducerState.answers.find(
              (answer) => answer.questionId === questionId
            );
            return (
              <Step
                stepId={questionId}
                key={questionId}
                header={headline}
                isValid={questionState.isValid}
                isWarning={isMissingProof}
                isComplete={questionState.isComplete}
                additionalHeight={questionImageKeys.length ? 100 : 0}
              >
                <Question
                  question={question}
                  questionState={questionState}
                  hasBackButton={index > 0}
                  saveAnswer={handleAnswerSave}
                  isSaving={updateAnswerLoading}
                  isActive={surveyReducerState.activeStep === index + 1}
                  uploadImageKeys={questionImageKeys}
                  highlightProofRequirement={isMissingProof}
                  hideActionButton={isSurveyInEditMode}
                  uploadId={surveyReducerState.uploadId}
                />
              </Step>
            );
          })}
        </Stepper>
        {isSurveyInEditMode && (
          <div css={doneEditingButtonWrapperStyle}>
            <Button
              data-transaction-name="Update all answers"
              label={svy_done_editing}
              disabled={updateAllAnswersLoading}
              isDisabled={updateAllAnswersLoading}
              onClick={() => handleAllAnswersUpdate(surveyReducerState.answers)}
            />
          </div>
        )}
      </SurveyContext.Provider>
    </Fragment>
  );
};

const SurveyPage = withTransaction('SurveyPage', 'component')(Survey);
export { SurveyPage };
