import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { css } from '@emotion/react';
import { useLabels } from '../../providers/LabelsProvider';
import { colors, spacing, font } from '../../config';
import { ActionButtonCell } from './ActionButtonCell';
import { AssessmentStatusCell } from './AssessmentStatusCell';

const tableStyle = css({
  width: '100%',
  borderCollapse: 'collapse',
});

const tableThStyle = css({
  textAlign: 'left',
  fontSize: font.size.sizeM1,
  borderBottom: `2px solid ${colors.neutralColors.slate20}`,
  height: spacing.size10,
  '&:first-of-type': {
    paddingLeft: spacing.size5,
  },
});

const tableTdStyle = css({
  fontSize: font.size.sizeM1,
  borderBottom: `1px solid ${colors.neutralColors.slate20}`,
  padding: `${spacing.size1}px 0`,
  '&:first-of-type': {
    paddingLeft: spacing.size5,
  },
});

const HomePageTable = ({ surveyListdata }) => {
  const {
    labels: { svy_table_header_year_of_evaluation, svy_table_header_status },
  } = useLabels();

  const columns = useMemo(
    () => [
      {
        Header: svy_table_header_year_of_evaluation,
        accessor: 'year',
      },
      {
        Header: svy_table_header_status,
        accessor: 'surveyState.status',
        Cell: AssessmentStatusCell,
      },
      {
        accessor: 'surveyState',
        Cell: ActionButtonCell,
      },
      {
        accessor: 'version',
        hidden: true,
      },
    ],
    [svy_table_header_year_of_evaluation, svy_table_header_status]
  );

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: surveyListdata,
      initialState: {
        hiddenColumns: columns
          .filter((column) => !!column.hidden)
          .map((column) => column.accessor),
      },
    });

  return (
    <table css={tableStyle} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr
            key={`tableheadergroup${index}`}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, index) => (
              <th
                css={tableThStyle}
                key={`tableheader${index}`}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={`tablerow${i}`} {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td
                    css={tableTdStyle}
                    key={`tablecell${index}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

HomePageTable.propTypes = {
  surveyListdata: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { HomePageTable };
