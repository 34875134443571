import { gql } from '@apollo/client';

const GET_CUSTOMERS = gql`
  query GetCustomers {
    getCustomers {
      id
      name
    }
  }
`;

const ACCEPT_TERMS = gql`
  mutation AcceptTerms($customerId: String!) {
    acceptTerms(customerId: $customerId)
  }
`;

const GET_USER_INFO = gql`
  query GetUserInfo($customerId: String!) {
    getTermsState(customerId: $customerId) {
      accepted
    }
    getCustomerAssignments(customerId: $customerId) {
      perform
    }
  }
`;

export { GET_CUSTOMERS, ACCEPT_TERMS, GET_USER_INFO };
