import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Button } from '@lego/klik-react';
import { Link } from 'react-router-dom';
import { useLabels } from '../../providers/LabelsProvider';
import { routes } from '../RouteHandler';
import { surveyStatus } from '../../constants';

const actionButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const ActionButtonCell = ({ cell }) => {
  const {
    labels: { svy_start_button, svy_display_button, svy_end_edit },
  } = useLabels();

  const {
    value: { isEditable, status },
    row: {
      values: { year, version },
    },
  } = cell;

  const getActionButton = (isEditable, status) => {
    if (isEditable && status === surveyStatus.NOT_SUBMITTED) {
      return (
        <Link to={`/${routes.survey}/${year}/${version}`}>
          <Button
            data-transaction-name="Start Survey"
            label={svy_start_button}
            size="small"
          />
        </Link>
      );
    }

    if (
      isEditable &&
      (status === surveyStatus.CHANGES_REQUIRED ||
        status === surveyStatus.RESCORING_REQUIRED)
    ) {
      return (
        <Link
          to={{
            pathname: `/${routes.survey}/${year}/${version}`,
            search: '?mode=edit',
          }}
        >
          <Button
            data-transaction-name="Edit Survey"
            label={svy_end_edit}
            size="small"
          />
        </Link>
      );
    }

    return (
      <Link to={`/${routes.review}/${year}/${version}`}>
        <Button
          data-transaction-name="Review Survey"
          label={svy_display_button}
          size="small"
          variant="outline"
        />
      </Link>
    );
  };

  return (
    <div css={actionButtonWrapperStyle}>
      {getActionButton(isEditable, status)}
    </div>
  );
};

ActionButtonCell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export { ActionButtonCell };
