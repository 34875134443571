import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClientProvider } from './providers/ApolloClientProvider';
import { KlikContextProvider } from '@lego/klik-react';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from './providers/UserProvider';
import { LabelsProvider } from './providers/LabelsProvider';
import { RouteHandler } from './components/RouteHandler';
import { logger } from './utils/logger';
import { ErrorPageNotTranslated } from './components/ErrorPageNotTranslated';
import { snackbarConfig } from './utils/snackbar';
import { ErrorBoundary } from 'react-error-boundary';

import './App.css';
import './index.scss';
import { UsersnapProvider } from './providers/UserSnapProvider';

const App = () => {
  useEffect(() => {
    // Init error logging to Elastic APM
    logger.init();
  }, []);

  return (
    <ApolloClientProvider>
      <KlikContextProvider>
        <ErrorBoundary fallback={<ErrorPageNotTranslated />}>
          <UserProvider>
            <ToastContainer {...snackbarConfig} />
            <LabelsProvider>
              <UsersnapProvider>
                <Router>
                  <RouteHandler />
                </Router>
              </UsersnapProvider>
            </LabelsProvider>
          </UserProvider>
        </ErrorBoundary>
      </KlikContextProvider>
    </ApolloClientProvider>
  );
};

export { App };
