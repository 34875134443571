import PropTypes from 'prop-types';
import { jsx, css } from '@emotion/core';
import { media, font, spacing } from '../config';

const headingSizes = {
  h1: {
    fontSize: font.size.size4,
    lineHeight: font.lineHeight.s,
    margin: `${spacing.size16}px 0 ${spacing.size8}px`,
    mobile: {
      margin: `${spacing.size10}px 0 ${spacing.size6}px`,
      fontSize: font.size.size3,
      lineHeight: font.lineHeight.s,
    },
  },
  h2: {
    fontSize: font.size.size3,
    lineHeight: font.lineHeight.s,
    margin: `${spacing.size10}px 0 ${spacing.size6}px`,
    mobile: {
      margin: `${spacing.size8}px 0 ${spacing.size4}px`,
      fontSize: font.size.size2,
      lineHeight: font.lineHeight.s,
    },
  },
};

const defaultHeaderMargin = `${spacing.size10}px 0 ${spacing.size8}px`;

const headingStyle = (type, additionalStyles) =>
  css(
    {
      fontWeight: font.weight.medium,
      fontSize: headingSizes[type]?.mobile.fontSize || font.size.size3,
      lineHeight: headingSizes[type]?.mobile.lineHeight || font.lineHeight.s,
      margin: headingSizes[type]?.mobile.margin || defaultHeaderMargin,
      [media.medium]: {
        fontSize: headingSizes[type]?.fontSize || font.size.size4,
        lineHeight: headingSizes[type]?.lineHeight || font.lineHeight.s,
        margin: headingSizes[type]?.mobile.margin || defaultHeaderMargin,
      },
    },
    additionalStyles
  );

const Heading = ({ type, text, additionalStyles, ...props }) => {
  return jsx(
    type,
    { css: headingStyle(type, additionalStyles), ...props },
    text
  );
};

Heading.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalStyles: PropTypes.object,
};

export { Heading };
