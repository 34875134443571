import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { useLabels } from '../providers/LabelsProvider';
import { css } from '@emotion/react';
import { LogOutBold } from '@lego/klik-icons-react';
import { Header as HeaderComponent, Button } from '@lego/klik-react';
import { spacing, media, colors, font } from '../config';
import { Logo } from '../components/Logo';
import { routes } from './RouteHandler';
import { Tooltip } from '../components/tooltip/Tooltip';

const logoWrapperStyle = css({
  cursor: 'pointer',
});

const logoutButtonStyle = css({
  position: 'absolute',
  right: 0,
  top: spacing.size2,
  [media.medium]: {
    top: spacing.size4,
  },
  [media.large]: {
    right: spacing.size9,
  },
});

const devMarkStyle = css({
  position: 'absolute',
  left: '42%',
  top: spacing.size1,
  fontSize: font.size.sizeM2,
  color: colors.boldColors.lightBlue.lightBlue90,
  fontWeight: font.weight.medium,
  [media.medium]: {
    fontSize: font.size.size2,
    top: spacing.size5,
    left: '48%',
  },
});

const customerNameStyle = css({
  display: 'none',
  [media.medium]: {
    position: 'absolute',
    right: 60,
    top: 28,
    textAlign: 'right',
    color: colors.boldColors.lightBlue.lightBlue50,
    display: 'block',
    whiteSpace: 'nowrap',
    maxWidth: 280,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: font.weight.medium,
  },
  [media.large]: {
    right: 120,
    maxWidth: 580,
  },
});

const mobileCustomerNameBarStyle = css({
  backgroundColor: colors.boldColors.lightBlue.lightBlue30,
  color: colors.boldColors.lightBlue.lightBlue100,
  fontWeight: font.weight.bold,
  fontSize: font.size.sizeM2,
  textAlign: 'right',
  padding: `${spacing.size2}px ${spacing.size4}px ${spacing.size1}px`,
  display: 'block',
  [media.medium]: {
    display: 'none',
  },
});

const Header = () => {
  const history = useHistory();
  const {
    labels: {
      svy_top_logo,
      svy_sign_out,
      svy_tooltip_back_to_frontpage,
      svy_tooltip_customer_name,
    },
  } = useLabels();

  const { isLoggedIn, signOut, selectedCustomer } = useUser();

  const handleLogoClick = () => history.push(`/${routes.homepage}`);

  return (
    <Fragment>
      <HeaderComponent>
        <HeaderComponent.Brand
          label={svy_top_logo}
          logo={
            <span css={logoWrapperStyle}>
              <Tooltip content={svy_tooltip_back_to_frontpage}>
                <Logo />
              </Tooltip>
            </span>
          }
          onClick={handleLogoClick}
        />

        {process.env.MCP_ENV !== 'production' && (
          <div css={devMarkStyle}>{process.env.MCP_ENV}</div>
        )}

        {isLoggedIn && selectedCustomer.name && (
          <Tooltip content={svy_tooltip_customer_name}>
            <div css={customerNameStyle}>{selectedCustomer.name}</div>
          </Tooltip>
        )}
        {isLoggedIn && (
          <div css={logoutButtonStyle}>
            <Tooltip content={svy_sign_out}>
              <Button
                data-transaction-name="Sign out"
                aria-label="Sign out"
                icon={
                  <LogOutBold css={{ color: colors.neutralColors.white }} />
                }
                variant="ghost"
                onClick={signOut}
              />
            </Tooltip>
          </div>
        )}
      </HeaderComponent>
      {isLoggedIn && selectedCustomer.name && (
        <div css={mobileCustomerNameBarStyle}>{selectedCustomer.name}</div>
      )}
    </Fragment>
  );
};

export { Header };
