import { colors } from '../config';
import { useLabels } from '../providers/LabelsProvider';
import { Step } from './stepper';

const MissingProofExplainer = () => {
  const {
    labels: { svy_attachment_missing, svy_some_answers_missing_attachments },
  } = useLabels();

  return (
    <div data-testid="missingProofExplainer">
      <Step
        header={`- ${svy_attachment_missing}`}
        stepId="-1"
        isComplete={true}
        isLast={true}
        isWarning={true}
      ></Step>
      <h1 css={{ color: colors.utilityColors.warning.warning400 }}>
        {svy_some_answers_missing_attachments}
      </h1>
    </div>
  );
};

export { MissingProofExplainer };
