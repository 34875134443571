import { Redirect } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { routes } from './RouteHandler';

const RootPage = () => {
  const { isLoggedIn, selectedCustomer } = useUser();

  if (!isLoggedIn) {
    return <Redirect to={`/${routes.login}`} />;
  }

  if (!selectedCustomer.id) {
    return <Redirect to={`/${routes.customerLogin}`} />;
  }

  if (selectedCustomer.hasAcceptedTerms === false) {
    return <Redirect to={`/${routes.terms}`} />;
  }

  return <Redirect to={`/${routes.homepage}`} />;
};

export { RootPage };
