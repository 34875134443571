import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { spacing, padding, media } from '../config';

const containerStyle = css({
  maxWidth: '768px',
  margin: 'auto',
  padding: `${spacing.size4}px ${padding.medium}px ${spacing.size10 * 2}px`,
  [media.medium]: {
    padding: `${spacing.size4}px ${padding.medium}px ${spacing.size10 * 4}px`,
  },
});

const CenterContentWrapper = ({ children }) => {
  return <div css={containerStyle}>{children}</div>;
};

CenterContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CenterContentWrapper };
