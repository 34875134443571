import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { useLabels } from '../providers/LabelsProvider';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';

function useFetchImagesFromS3({ uploadId, skip, onCompleted }) {
  const [imagesAreLoading, setImagesAreLoading] = useState(false);
  const [uploadedImagesKeys, setUploadedImagesKeys] = useState(null);
  const [imagesFetchError, setImagesFetchError] = useState(false);

  const {
    labels: {
      svy_snackbar_error_header,
      svy_snackbar_error_uploaded_images_not_available,
    },
  } = useLabels();

  useEffect(() => {
    if (!uploadId || skip) return;

    async function fetchUploadedImages() {
      let uploadedImagesFromS3 = [];
      try {
        setImagesAreLoading(true);
        uploadedImagesFromS3 = await Storage.list(uploadId);

        const uploadedImageKeysFromS3 = uploadedImagesFromS3.map(
          (imageObject) => imageObject.key
        );
        setUploadedImagesKeys(uploadedImageKeysFromS3);
        setImagesAreLoading(false);
        onCompleted &&
          typeof onCompleted === 'function' &&
          onCompleted(uploadedImageKeysFromS3);
      } catch (error) {
        setImagesAreLoading(false);
        setImagesFetchError(error);
        setUploadedImagesKeys([]);

        generalApiErrorHandler({
          error,
          snackbarHeader: svy_snackbar_error_header,
          snackbarMessage: svy_snackbar_error_uploaded_images_not_available,
        });
      }
    }

    fetchUploadedImages();
  }, [
    uploadId,
    skip,
    onCompleted,
    svy_snackbar_error_header,
    svy_snackbar_error_uploaded_images_not_available,
  ]);

  return {
    imagesAreLoading,
    uploadedImagesKeys,
    imagesFetchError,
  };
}

export { useFetchImagesFromS3 };
