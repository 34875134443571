import { css } from '@emotion/react';
import { font, spacing, colors, media } from '../config';
import legoErrorPageSvg from '../assets/lego-error-page.svg';
import { Button } from '@lego/klik-react';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'red',
  background: `linear-gradient(${colors.boldColors.darkBlue.darkBlue80}, ${colors.boldColors.darkBlue.base})`,
  color: colors.neutralColors.white,
  textAlign: 'center',
  paddingBottom: 120,
  height: '100vh',
});

const imageWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: 40,
  width: 200,
  [media.medium]: {
    marginTop: 80,
    width: 'auto',
  },
});

const svgStyle = css({
  width: '100%',
  height: 'auto',
});

const svgWrapperLeftStyle = css({
  width: 120,
  marginRight: spacing.size5,
  '& > img': {
    transform: 'matrix(-1, 0, 0, 1, 0, 0);',
  },
});

const svgWrapperRightStyle = css({
  width: 220,
});

const headerStyle = css({
  fontWeight: font.weight.medium,
  marginBottom: spacing.size7,
  marginTop: spacing.size12,
  fontSize: font.size.size3,
  lineHeight: font.lineHeight.s,
  padding: `0 ${spacing.size4}px`,
  [media.medium]: {
    fontSize: font.size.size5,
  },
});

const bodyTextStyle = css({
  maxWidth: 500,
  lineHeight: font.lineHeight.m,
  padding: `0 ${spacing.size4}px`,
});

const backButtonStyle = {
  marginTop: spacing.size4,
  color: colors.neutralColors.white,
  borderColor: colors.neutralColors.white,
};

const ErrorPageNotTranslated = () => (
  <div>
    <div css={containerStyle}>
      <div css={imageWrapperStyle}>
        <div css={svgWrapperLeftStyle}>
          <img src={legoErrorPageSvg} css={svgStyle} />
        </div>
        <div css={svgWrapperRightStyle}>
          <img src={legoErrorPageSvg} css={svgStyle} />
        </div>
      </div>
      <h1 css={headerStyle}>Something went wrong</h1>
      <p css={bodyTextStyle}>
        The error has already been picked up by our monitoring service and we
        will fix the issue as soon as possible. <br /> Please try again later.
      </p>
      <Button
        data-transaction-name="Go back"
        onClick={() => window.history.go(-1)}
        label="Go Back"
        variant="outline"
        style={backButtonStyle}
      />
    </div>
  </div>
);

export { ErrorPageNotTranslated };
