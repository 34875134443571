import PropTypes from 'prop-types';
import { AssessmentStatus } from './AssessmentStatus';

const AssessmentStatusCell = ({ cell: { value } }) => (
  <AssessmentStatus status={value} />
);

AssessmentStatusCell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export { AssessmentStatusCell };
