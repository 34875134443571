import { css } from '@emotion/react';
import { colors } from '../config';
import { CenterContentWrapper } from '../components/CenterContentWrapper';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

const layoutWrapperStyle = css({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const mainStyle = css({
  flexGrow: 1,
  minHeight: 700,
  backgroundColor: colors.neutralColors.white,
});

function withMainLayout(Component) {
  function WithMainLayout({ ...props }) {
    return (
      <div css={layoutWrapperStyle}>
        <Header />
        <main css={mainStyle}>
          <CenterContentWrapper>
            <Component {...props} />
          </CenterContentWrapper>
        </main>
        <Footer />
      </div>
    );
  }

  WithMainLayout.displayName = `WithMainLayout(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithMainLayout;
}

export { withMainLayout };
