import { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Checkbox } from '@lego/klik-react';
import { spacing } from '../../config';
import { SurveyContext, updateAnswerAction } from '../survey/reducer';
import { AnswerLabel } from './AnswerLabel';

const multiChoiceWrapperStyle = css({
  marginTop: spacing.size8,
});

const MultiChoice = ({ question, questionState }) => {
  const { dispatch } = useContext(SurveyContext);
  const { questionId, questionType, answerOptions } = question;
  return (
    <div>
      {answerOptions.map((answer, index) => (
        <div key={index} css={multiChoiceWrapperStyle}>
          <Checkbox
            checked={questionState.selectedAnswer.includes(answer.answerId)}
            onChange={(event) =>
              dispatch(
                updateAnswerAction(questionId, questionType, event.target.value)
              )
            }
            label={<AnswerLabel labelText={answer.text} score={answer.score} />}
            value={answer.answerId}
          />
        </div>
      ))}
    </div>
  );
};

MultiChoice.propTypes = {
  question: PropTypes.object.isRequired,
  questionState: PropTypes.object.isRequired,
};

export { MultiChoice };
