import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { media } from '../config';
import logo from '../assets/lego-web.svg';

const logoStyle = css({
  width: 32,
  [media.medium]: {
    width: 40,
  },
  [media.large]: {
    width: 48,
  },
});

const Logo = forwardRef((props, ref) => (
  <img ref={ref} src={logo} alt="Lego logo" css={logoStyle} />
));

Logo.displayName = 'Logo';

export { Logo };
