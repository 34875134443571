import { featureFlags } from '../../config';
import { questionTypes } from '../../constants';
import { areArraysEqual } from '../../utils/areArraysEqual';
export const questionValidator = (isMultiSelect, answerValue) => {
  if (isMultiSelect) return true;
  if (answerValue) return true;
  return false;
};

const allAnswersAreExempt = (selectedAnswers, answersExemptFromAttachments) => {
  return selectedAnswers.every((answer) =>
    answersExemptFromAttachments.includes(answer)
  );
};

/**
 * Determine if proof should be asked for based on the selected answers.
 * @param {Number} nrOfUploadedImages - The number of uploaded images
 * @param {Array} selectedAnswers - The selected answers
 * @param {Array} answersExemptFromAttachments - The answers that are exempt from needing proof
 * @param {String} questionType - The type of question
 * @returns {Boolean}
 */
export const shouldAskForProof = (
  nrOfUploadedImages,
  selectedAnswers,
  answersExemptFromAttachments,
  questionType
) => {
  const isMultiSelect = questionType === questionTypes.NON_BINARY_MULTI_CHOICE;

  if (selectedAnswers.length === 0) return false;
  if (nrOfUploadedImages > 0) return false;

  if (isMultiSelect) {
    if (selectedAnswers.length === 0) return false;
    return true;
  }

  if (answersExemptFromAttachments.length == 0) {
    return false;
  }

  if (areArraysEqual(selectedAnswers, answersExemptFromAttachments)) {
    return false;
  }

  if (allAnswersAreExempt(selectedAnswers, answersExemptFromAttachments)) {
    return false;
  }

  return true;
};

/**
 * Enhance the questions with answers and proof data.
 * @param {Array} questions - The questions
 * @param {Array} answers - The answers
 * @param {Array} uploadedImagesKeys - The uploaded image keys
 * @returns {Array}
 */
export const enhanceQuestionsWithAnswersAndProof = (
  questions,
  answers,
  uploadedImagesKeys
) => {
  return questions.map((question) => {
    const savedAnswer = answers.find(
      (answer) => answer.questionId === question.questionId
    );

    let questionImageKeys;
    if (uploadedImagesKeys) {
      questionImageKeys = getQuestionImageKeys(
        uploadedImagesKeys,
        question.questionId
      );
    } else {
      questionImageKeys = savedAnswer?.images?.map((image) =>
        image.replace('public/', '')
      );
    }

    const answerIdsExemptFromAttachments =
      question.answersExemptFromAttachments.map((answer) => answer.answerId) ??
      [];

    const isMissingProof = shouldAskForProof(
      questionImageKeys?.length ?? 0,
      savedAnswer.selectedAnswer,
      answerIdsExemptFromAttachments,
      question.questionType
    );

    return {
      ...question,
      savedAnswer,
      questionImageKeys,
      isMissingProof,
    };
  });
};

export const shouldAssessmentBeCheckedForProof = (surveyData) => {
  const { year } = surveyData;
  const { requiredProof } = featureFlags;

  const isYearCovered = year >= requiredProof.startYear;
  return isYearCovered;
};

export const adjustAssessmentsBasedOnFeatureFlags = (
  enhancedQuestionData,
  surveyData
) => {
  const shouldBeCheckedForProof = shouldAssessmentBeCheckedForProof(surveyData);

  if (!shouldBeCheckedForProof) {
    return enhancedQuestionData.map((question) => {
      question.isMissingProof = false;
      return question;
    });
  }

  return enhancedQuestionData;
};

export const removeTypenameFromAllAnswers = (answerArr) => {
  return answerArr.map((answer) => {
    const answerCopy = { ...answer };
    delete answerCopy['__typename'];
    return answerCopy;
  });
};

export const getActiveStep = (answerArr) => {
  const answersCompleted = answerArr.filter((answer) => answer.isComplete);
  return answersCompleted.length + 1;
};

export const getMultiSelectAnswers = (answerValue, currentAnswerState) => {
  if (currentAnswerState.includes(answerValue)) {
    return currentAnswerState.filter((answerId) => answerId !== answerValue);
  }
  if (answerValue) return [...currentAnswerState, answerValue];
  return currentAnswerState;
};

export const getQuestionImageKeys = (imageKeysArr, questionId) => {
  if (!imageKeysArr) return [];

  const regex = new RegExp(`${questionId}`);
  return imageKeysArr.filter((imageKey) => imageKey.match(regex));
};

export const areAllAnswersComplete = (answers) => {
  if (!answers) return null;
  return answers.every((answer) => answer.isComplete);
};

export const isAnyQuestionMissingProof = (questions) =>
  questions.some((question) => question.isMissingProof);
