import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { routes } from '../components/RouteHandler';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, selectedCustomer } = useUser();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return <Redirect to={`/${routes.login}`} />;
        }

        if (!selectedCustomer.id) {
          return <Redirect to={`/${routes.customerLogin}`} />;
        }

        if (selectedCustomer.hasAcceptedTerms === false) {
          return <Redirect to={`/${routes.terms}`} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  rest: PropTypes.object,
};

export { AuthRoute };
