import { logger } from './logger';
import { addSnackbar, snackbarTypes } from './snackbar';
import { labelTranslationsFallback } from './labelTranslationsFallback';

const { svy_snackbar_error_header, svy_snackbar_error_default_text } =
  labelTranslationsFallback;

const generalApiErrorHandler = ({
  error,
  snackbarHeader = svy_snackbar_error_header,
  snackbarMessage = svy_snackbar_error_default_text,
}) => {
  addSnackbar({
    header: snackbarHeader,
    text: snackbarMessage,
    type: snackbarTypes.ERROR,
  });
  logger.logError(error);
};

export { generalApiErrorHandler };
