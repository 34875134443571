import React, { useEffect, useState, useContext } from 'react';
import { loadSpace } from '@usersnap/browser';
import PropTypes from 'prop-types';
import { useUser } from './UserProvider';

const USERSNAP_GLOBAL_API_KEY = process.env.USERSNAP_GLOBAL_API_KEY ?? '';
const USERSNAP_LT_CONTACT_API_KEY =
  process.env.USERSNAP_LT_CONTACT_API_KEY ?? '';

export const UserSnapContext = React.createContext(null);

const enrichInitParams = (initParams, locale, customerId, email) => {
  return { ...initParams, locale, customerId, email, visitor: email };
};

const doesHaveValidContact = (isLoggedIn, selectedCustomer) =>
  isLoggedIn && selectedCustomer?.legoContactEmail != '';

export const UsersnapProvider = ({ initParams, children }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);
  const { cognitoData, isLoggedIn, selectedCustomer } = useUser();

  const hasValidContact = doesHaveValidContact(isLoggedIn, selectedCustomer);
  const { id: customerId, legoContactEmail } = selectedCustomer ?? {};
  let locale, email;

  if (isLoggedIn && cognitoData) {
    const payload = cognitoData?.signInUserSession?.idToken?.payload;
    locale = payload['locale']?.toLowerCase();
    email = payload['email'];
  }

  useEffect(() => {
    loadSpace(USERSNAP_GLOBAL_API_KEY).then((api) => {
      api.init(enrichInitParams(initParams, locale, customerId, email));

      if (hasValidContact) {
        api.show(USERSNAP_LT_CONTACT_API_KEY);
      }

      const handleWidgetBeforeSubmit = (event) => {
        if (hasValidContact) {
          event.api.setValue('custom', {
            legoContactEmail: legoContactEmail,
          });
        }
      };
      api.on('beforeSubmit', handleWidgetBeforeSubmit);
      setUsersnapApi(api);
    });
  }, [
    hasValidContact,
    initParams,
    locale,
    email,
    customerId,
    legoContactEmail,
  ]);

  return (
    <UserSnapContext.Provider value={usersnapApi}>
      {children}
    </UserSnapContext.Provider>
  );
};

export function useUsersnapApi() {
  return useContext(UserSnapContext);
}

UsersnapProvider.propTypes = {
  initParams: PropTypes.object,
  children: PropTypes.node,
};
