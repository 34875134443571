import { css } from '@emotion/react';
import { Button } from '@lego/klik-react';
import { Link } from 'react-router-dom';
import { StatusSuccessBold } from '@lego/klik-icons-react';
import { useLabels } from '../providers/LabelsProvider';
import { useUser } from '../providers/UserProvider';
import { spacing, media, colors } from '../config';
import { routes } from './RouteHandler';
import { Heading } from './Heading';
import { withTransaction } from '@elastic/apm-rum-react';

const contentWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 550,
  margin: '0 auto',
});

const iconStyle = css({
  color: colors.boldColors.lightBlue.lightBlue100,
  height: 80,
  width: 80,
  margin: `${spacing.size8}px 0 ${spacing.size6}px`,
  [media.medium]: {
    height: 130,
    width: 130,
    margin: `${spacing.size10}px 0 ${spacing.size16}px`,
  },
});

const bodyTextStyle = css({
  textAlign: 'center',
});

const headingStyle = css({
  textAlign: 'center',
});

const buttonsWrapperStyle = css({
  display: 'flex',
  marginTop: spacing.size16,
});

const firstButtonStyle = css({
  marginRight: spacing.size8,
});

const Confirmation = () => {
  const {
    labels: {
      svy_end_thanks_headline,
      svy_end_thanks_details,
      svy_sign_out,
      svy_back_to_homepage,
    },
  } = useLabels();
  const { signOut } = useUser();

  return (
    <div css={contentWrapperStyle}>
      <StatusSuccessBold css={iconStyle} />
      <Heading
        type="h2"
        additionalStyles={headingStyle}
        text={svy_end_thanks_headline}
      />
      <p css={bodyTextStyle}>{svy_end_thanks_details}</p>
      <div css={buttonsWrapperStyle}>
        <div css={firstButtonStyle}>
          <Link to={`/${routes.homepage}`}>
            <Button
              data-transaction-name="Back to homepage"
              label={svy_back_to_homepage}
              css={firstButtonStyle}
              variant="ghost"
            />
          </Link>
        </div>
        <Button
          data-transaction-name="Sign out"
          label={svy_sign_out}
          onClick={signOut}
        />
      </div>
    </div>
  );
};

const ConfirmationPage = withTransaction(
  'ConfirmationPage',
  'component'
)(Confirmation);
export { ConfirmationPage };
