import { Redirect } from 'react-router-dom';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { useUser } from '../providers/UserProvider';
import { useLabels } from '../providers/LabelsProvider';
import { colors, spacing } from '../config';
import { OverlayPortal } from './OverlayPortal';
import { Spinner } from './spinner/Spinner';
import { routes } from './RouteHandler';
import { LoginLayout } from '../layouts/LoginLayout';
import userSvg from '../assets/lego_user.svg';
import { GET_CUSTOMERS } from '../queries/user';
import { userHasNoCustomersKey } from '../constants/localStorageKeys';
import {
  addCustomBreadcrumb,
  addDefaultBreadcrumb,
  throwErrorWithTransactionName,
} from '../utils/elasticApmHeler';

const listStyle = css({
  maxHeight: 320,
  overflow: 'hidden',
  overflowY: 'scroll',
  listStyle: 'none',
  paddingRight: spacing.size2,
});

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  minHeight: 64,
  borderBottom: `2px solid ${colors.neutralColors.slate20}`,
  backgroundColor: colors.neutralColors.white,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colors.neutralColors.slate05,
  },
});

const iconStyle = css({
  marginRight: spacing.size4,
});

const textStyle = css({
  color: colors.boldColors.lightBlue.base,
});

const MultiCustomerLoginPage = () => {
  addDefaultBreadcrumb('Customers loading', 'CustomerLoginPage');
  const { selectedCustomer, handleSelectedCustomerUpdate, signOut } = useUser();

  const {
    labels: { svy_multi_customer_login_heading },
  } = useLabels();

  addDefaultBreadcrumb('Query customers', 'CustomerLoginPage');
  const {
    data: customersData,
    loading: getCustomersLoading,
    error: getCustomersError,
  } = useQuery(GET_CUSTOMERS);

  if (selectedCustomer.id) {
    addDefaultBreadcrumb(
      'Customer ID selected',
      'CustomerLoginPage.selectedCustomer'
    );
    return <Redirect to={`/${routes.terms}`} />;
  }

  if (getCustomersLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  // Error boundary catches this error
  if (getCustomersError) {
    throwErrorWithTransactionName(getCustomersError, 'MultiCustomerLoginPage');
  }

  if (customersData && !customersData.getCustomers.length) {
    addDefaultBreadcrumb(
      'No customers found for user',
      'CustomerLoginPage.customerData'
    );
    window.localStorage.setItem(userHasNoCustomersKey, true);
    signOut();

    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  if (customersData && customersData.getCustomers.length === 1) {
    const theOnlyCustomer = customersData.getCustomers[0];

    addCustomBreadcrumb({
      message: 'Single customer found for user',
      category: 'CustomerLoginPage.customerData',
      data: { id: theOnlyCustomer.id },
    });
    handleSelectedCustomerUpdate({
      id: theOnlyCustomer.id,
      name: theOnlyCustomer.name,
    });
  }

  const { getCustomers: customers } = customersData;

  return (
    <LoginLayout title={svy_multi_customer_login_heading}>
      <ul css={listStyle}>
        {customers.map((customer) => (
          <li
            key={`customer-${customer.id}`}
            css={listItemStyle}
            onClick={() =>
              handleSelectedCustomerUpdate({
                id: customer.id,
                name: customer.name,
              })
            }
          >
            <img src={userSvg} alt="User icon" css={iconStyle} />
            <span css={textStyle}>{`${customer.id} - ${customer.name}`}</span>
          </li>
        ))}
      </ul>
    </LoginLayout>
  );
};

export { MultiCustomerLoginPage };
