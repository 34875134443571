import PropTypes from 'prop-types';
import { Modal, Overlay, Button } from '@lego/klik-react';
import { Delete } from '@lego/klik-icons-react';
import { useLabels } from '../../../providers/LabelsProvider';

const DeleteImageModal = ({
  imageId,
  closeDeleteImageModal,
  deleteImageHandler,
}) => {
  const {
    labels: {
      svy_cancel_button,
      svy_delete_button,
      svy_delete_image_confirmation_modal_header,
      svy_delete_image_confirmation_modal_body,
    },
  } = useLabels();
  return (
    <Overlay isOpen={true} onClick={closeDeleteImageModal}>
      <Modal
        status="error"
        icon={<Delete />}
        heading={
          <Modal.Heading>
            {svy_delete_image_confirmation_modal_header}
          </Modal.Heading>
        }
        body={
          <Modal.Body>{svy_delete_image_confirmation_modal_body}</Modal.Body>
        }
        action={
          <Modal.Action>
            <Button
              data-transaction-name="Confirm delete image"
              aria-label="Confirm delete image button"
              label={svy_delete_button}
              onClick={() => deleteImageHandler(imageId)}
            />
            <Button
              data-transaction-name="Cancel delete image"
              label={svy_cancel_button}
              variant="outline"
              onClick={closeDeleteImageModal}
            />
          </Modal.Action>
        }
        exitClickHandler={closeDeleteImageModal}
      />
    </Overlay>
  );
};

DeleteImageModal.propTypes = {
  imageId: PropTypes.string.isRequired,
  closeDeleteImageModal: PropTypes.func.isRequired,
  deleteImageHandler: PropTypes.func.isRequired,
};

export { DeleteImageModal };
