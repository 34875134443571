export const replaceContactEmail = (
  welcomeText,
  emailPlaceholder,
  contactEmail
) => {
  const emailPlaceholderPattern = new RegExp(`${emailPlaceholder}`, 'i');
  const replacedText = welcomeText.replace(
    emailPlaceholderPattern,
    `<a href="mailto:${contactEmail}">${contactEmail}</a>`
  );
  return replacedText;
};
