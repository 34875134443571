import { useLabels } from '../providers/LabelsProvider';
import { useUser } from '../providers/UserProvider';
import { css } from '@emotion/react';
import { spacing, media, colors, font } from '../config';
import { Logo } from '../components/Logo';

const wrapperStyle = css({
  backgroundColor: colors.neutralColors.slate05,
  padding: spacing.size4,
  [media.medium]: {
    padding: spacing.size12,
  },
});

const hrStyle = css({
  border: `1px solid ${colors.neutralColors.slate30}`,
});

const topSectionStyle = css({
  padding: `${spacing.size4}px ${spacing.size5}px ${spacing.size6}px`,
});

const contactDetailsTextStyle = css({
  fontWeight: font.weight.medium,
  marginBottom: spacing.size1,
});

const contactEmailLinkStyle = css({
  marginBottom: spacing.size6,
  [media.medium]: {
    marginBottom: spacing.size2,
  },
});

const linksWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [media.medium]: {
    flexDirection: 'row',
  },
});

const rightLinksWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    marginLeft: 0,
  },
  [media.medium]: {
    flexDirection: 'row',
    '& > *': {
      marginLeft: spacing.size8,
    },
  },
});

const bottomSectionStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: `${spacing.size8}px ${spacing.size5}px ${spacing.size6}px`,
  flexDirection: 'column',
  [media.medium]: {
    flexDirection: 'row',
  },
});

const bodyTextStyle = css({
  paddingRight: spacing.size6,
  '& > p': {
    marginBottom: spacing.size4,
  },
});

const logoStyle = css({
  width: 48,
  height: 48,
});

const Footer = () => {
  const { selectedCustomer } = useUser();
  const {
    labels: { svy_footer_contact },
    richTextLabels: {
      svy_footer_text,
      svy_footer_legal_notice_link,
      svy_footer_privacy_policy_link,
    },
  } = useLabels();
  return (
    <footer css={wrapperStyle}>
      <div css={topSectionStyle}>
        <h4 css={contactDetailsTextStyle}>{svy_footer_contact}</h4>
        <div css={linksWrapperStyle}>
          {selectedCustomer.legoContactEmail && (
            <a
              css={contactEmailLinkStyle}
              href={`mailto:${selectedCustomer.legoContactEmail}`}
            >
              {selectedCustomer.legoContactEmail}
            </a>
          )}
          <div css={rightLinksWrapperStyle}>
            <span
              dangerouslySetInnerHTML={{ __html: svy_footer_legal_notice_link }}
            ></span>
            <span
              dangerouslySetInnerHTML={{
                __html: svy_footer_privacy_policy_link,
              }}
            ></span>
          </div>
        </div>
      </div>
      <hr css={hrStyle} />
      <div css={bottomSectionStyle}>
        <div
          css={bodyTextStyle}
          dangerouslySetInnerHTML={{ __html: svy_footer_text }}
        />
        <Logo css={logoStyle} />
      </div>
    </footer>
  );
};

export { Footer };
