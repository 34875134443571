import { css } from '@emotion/react';
import { Footer } from './Footer';
import { useLabels } from '../providers/LabelsProvider';
import { font, spacing, colors, media } from '../config';
import lego404 from '../assets/lego-404.svg';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'red',
  background: `linear-gradient(${colors.boldColors.darkBlue.darkBlue80}, ${colors.boldColors.darkBlue.base})`,
  color: colors.neutralColors.white,
  textAlign: 'center',
  paddingBottom: 120,
});

const svgStyle = css({
  width: 250,
  height: 250,
  alignSelf: 'flex-start',
  [media.medium]: {
    width: 500,
    height: 500,
  },
  [media.large]: {
    width: 700,
    height: 700,
  },
});

const headerStyle = css({
  fontSize: font.size.size5,
  fontWeight: font.weight.medium,
  marginBottom: spacing.size7,
  marginTop: -60,
  [media.medium]: {
    marginTop: -100,
  },
  [media.large]: {
    marginTop: -150,
  },
});

const bodyTextStyle = css({
  maxWidth: 500,
  lineHeight: font.lineHeight.m,
  padding: `0 ${spacing.size2}px`,
});

const contactMailLinkStyle = css({
  marginTop: spacing.size6,
  marginBottom: spacing.size12,
  '&:link, &:hover, &:active, &:visited': {
    color: colors.neutralColors.white,
    textDecoration: 'underline',
  },
});

const PageNotFound = () => {
  const {
    labels: { svy_404_page_text },
  } = useLabels();
  return (
    <div>
      <div css={containerStyle}>
        <img src={lego404} css={svgStyle} />
        <h1 css={headerStyle}>404</h1>
        <p css={bodyTextStyle}>{svy_404_page_text}</p>
        <a css={contactMailLinkStyle} href="mailto:something@here">
          contact@person.lego.com
        </a>
      </div>
      <Footer />
    </div>
  );
};

export { PageNotFound };
