import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { Button, Checkbox } from '@lego/klik-react';
import { useUser } from '../providers/UserProvider';
import { Header } from './Header';
import { CenterContentWrapper } from './CenterContentWrapper';
import { ACCEPT_TERMS } from '../queries/user';
import legoBrick from '../assets/lego-brick.svg';
import { spacing, media, font } from '../config';
import { useLabels } from '../providers/LabelsProvider';
import { OverlayPortal } from './OverlayPortal';
import { Spinner } from './spinner/Spinner';
import { routes } from './RouteHandler';
import { Heading } from './Heading';
import { Tooltip } from '../components/tooltip/Tooltip';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';

const iconStyle = css({
  marginTop: spacing.size3,
  width: 92,
  [media.medium]: {
    width: 184,
    marginTop: 0,
  },
});

const termsTextStyle = css({
  fontSize: font.size.size0,
  lineHeight: font.lineHeight.m,
  marginBottom: spacing.size5,
});

const buttonWrapper = css({
  marginTop: spacing.size6,
  [media.medium]: {
    marginTop: spacing.size16,
  },
});

const TermsPage = () => {
  const { isLoggedIn, selectedCustomer, handleSelectedCustomerUpdate } =
    useUser();

  const {
    labelsLoading,
    labels: {
      svy_intro_headline,
      svy_intro_details,
      svy_terms_accept_button_text,
      svy_snackbar_error_header,
      svy_snackbar_error_default_text,
      svy_tooltip_accept_terms_first,
    },
    richTextLabels: { svy_intro_policies },
  } = useLabels();

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [setAcceptance, { loading: isSetAcceptanceLoading }] = useMutation(
    ACCEPT_TERMS,
    {
      onCompleted: handleAcceptTermsResponse,
    }
  );

  function handleAcceptTermsResponse(response) {
    handleSelectedCustomerUpdate({
      hasAcceptedTerms: response?.acceptTerms || false,
    });
  }

  const handleStartSurveyButtonClick = async () => {
    try {
      await setAcceptance({
        variables: { customerId: selectedCustomer.id },
      });
    } catch (error) {
      generalApiErrorHandler({
        error,
        snackbarHeader: svy_snackbar_error_header,
        snackbarMessage: svy_snackbar_error_default_text,
      });
    }
  };

  if (!isLoggedIn) {
    return <Redirect to={`/${routes.login}`} />;
  }

  if (!selectedCustomer.id) {
    return <Redirect to={`/${routes.customerLogin}`} />;
  }

  if (selectedCustomer.hasAcceptedTerms) {
    return <Redirect to={`/${routes.homepage}`} />;
  }

  if (labelsLoading || isSetAcceptanceLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  return (
    <div>
      <Header />
      <CenterContentWrapper>
        <img src={legoBrick} alt="Big lego brick icon" css={iconStyle} />
        <Heading type="h1" text={svy_intro_headline} />
        <p css={termsTextStyle}>{svy_intro_details}</p>
        <Checkbox
          label={
            <span dangerouslySetInnerHTML={{ __html: svy_intro_policies }} />
          }
          checked={termsAccepted}
          onChange={() => setTermsAccepted((prevState) => !prevState)}
        />
        <div css={buttonWrapper}>
          <Tooltip
            content={svy_tooltip_accept_terms_first}
            disabled={termsAccepted}
          >
            <span css={{ position: 'absolute' }}>
              {/* Span element is only for making tooltip appear on disabled button */}
              <Button
                data-transaction-name="Accept Terms"
                aria-label="Accept survey terms"
                label={svy_terms_accept_button_text}
                disabled={!termsAccepted}
                isDisabled={!termsAccepted}
                onClick={() => handleStartSurveyButtonClick()}
              />
            </span>
          </Tooltip>
        </div>
      </CenterContentWrapper>
    </div>
  );
};

export { TermsPage };
