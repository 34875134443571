import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { OverlayPortal } from './OverlayPortal';
import { Spinner } from './spinner/Spinner';
import { RESET } from '../queries/survey';
import { useUser } from '../providers/UserProvider';

const Reset = () => {
  const { year } = useParams();
  const { staticUserVariables, handleSelectedCustomerUpdate } = useUser();
  const [resetData, { error: resetError }] = useMutation(RESET, {
    onCompleted: handleResetDataResponse,
  });

  function handleResetDataResponse(response) {
    if (response.reset) {
      handleSelectedCustomerUpdate({ id: null });
    }
  }

  useEffect(() => {
    async function initiateReset() {
      try {
        await resetData({
          variables: staticUserVariables({ year }),
        });
      } catch (error) {
        console.error(error);
      }
    }

    initiateReset();
  }, [year, resetData, staticUserVariables]);

  if (resetError) {
    return <div>Error: Reset failed!</div>;
  }

  return (
    <OverlayPortal>
      <Spinner />
    </OverlayPortal>
  );
};

export { Reset };
