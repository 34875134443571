import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Cross } from '@lego/klik-icons-react';
import { Button } from '@lego/klik-react';
import { spacing, colors, font } from '../config';

const contentWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const leftContainerStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
});

const iconStyle = css({
  marginRight: spacing.size3,
  '&:empty': {
    display: 'none',
  },
});

const textWrapperStyle = css({
  marginRight: spacing.size2,
});

const headerTextStyle = css({
  fontWeight: font.weight.medium,
});

const bodyTextStyle = css({
  fontSize: font.size.sizeM1,
  lineHeight: 1.125,
});

const buttonWrapperStyle = css({
  flexShrink: 0,
  marginTop: -spacing.size1,
});

const closeIconStyle = css({
  color: colors.neutralColors.slate60,
});

const SnackbarContent = ({ icon, header, text, onClose }) => {
  return (
    <div css={contentWrapperStyle}>
      <span css={leftContainerStyle}>
        <span css={iconStyle}>{icon}</span>
        <div css={textWrapperStyle}>
          <h4 css={headerTextStyle}>{header}</h4>
          <span css={bodyTextStyle}>{text}</span>
        </div>
      </span>
      <span css={buttonWrapperStyle}>
        <Button
          data-transaction-name="Close notification"
          aria-label="Close notification button"
          icon={<Cross css={closeIconStyle} />}
          variant="ghost"
          size="small"
          onClick={onClose}
        />
      </span>
    </div>
  );
};

SnackbarContent.propTypes = {
  icon: PropTypes.element,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClose: PropTypes.func,
};

export { SnackbarContent };
