export const labelTranslationsFallback = {
  labelsLoading: false,
  labels: {
    svy_status_in_lego_review: 'In LEGO® review',
    svy_loginpage_welcome_text:
      'Welcome to the LEGO® Functional Discount Self-Assessment',
    svy_intro_details:
      'The answers and justification you provide in the self-assessment need to be true and accurate and shall not be untruthful or misleading. The LEGO® Group reserves the right to perform sample checks to verify the accuracy of the information provided. Any submission of incorrect information may result in a reduction of future functional discount. Your answers and justification will only be shared with relevant stakeholders within the LEGO® Group, shall be stored and handled in compliance with applicable laws and will under no circumstances be shared with any third party. The assessment questionnaire is confidential and you are not permitted to share the content further.',
    svy_end_thanks_details:
      'Your answers will be reviewed. We expect to approve your self-assessment within the near future. We will contact you directly in case we have any questions regarding your answers or justifications.',
    svy_complete_page_total_discount_text:
      'Your provisional functional discount percentage based on your self-assessment is:',
    svy_status_changes_required: 'Changes required',
    svy_start_button: 'Start Assessment',
    svy_end_edit: 'Edit Assessment',
    svy_end_complete: 'Complete assessment',
    svy_cookie_banner_body_text:
      'We only use necessary cookies that enable core site functionality and are therefore always enabled.',
    svy_cookie_banner_header: 'Cookies are used to run this application.',
    svy_additional_comments_placeholder_text: 'Write here',
    svy_end_thanks_headline: 'Thank you for your submission',
    svy_end_finish: 'Finish Assessment',
    svy_review_headline: 'Review Assessment Form',
    svy_end_headline: 'Complete Assessment Form',
    svy_additional_comments: 'Justification',
    svy_multi_customer_login_heading: 'Choose a user to log in with',
    svy_intro_headline: 'Functional discount terms and conditions',
    svy_terms_accept_button_text: 'Accept',
    svy_error_page_body_text:
      'A server issue might have caused an error. Try to refresh the site or wait a couple of minutes and try again. If that does not work please contact your LEGO® representative to find a solution.',
    svy_error_page_header: "Somethings's gone missing.",
    svy_404_page_text:
      'That link may be broken or the page may have been moved. If the problem persists, please contact your LEGO representative:',
    svy_top_logo: 'LEGO® Functional Discount Self-Assessment',
    svy_footer_contact: 'Contact Details',
    svy_footer_cookie_policy_link: 'Cookie policy',
    svy_sign_in_button: 'Sign in',
    svy_cancel_button: 'Cancel',
    svy_back_button: 'Back',
    svy_next_button: 'Next',
    svy_delete_button: 'Delete',
    svy_delete_image_confirmation_modal_header: 'Delete image?',
    svy_delete_image_confirmation_modal_body:
      'Are you sure you want to delete the image?',
    svy_max_number_of_images: 'Max. 5 images',
    svy_end_review: 'Review',
    svy_uploading_image_text: 'Uploading image, please wait ...',
    svy_done_editing: 'Done',
    svy_attachments: 'Attachments',
    svy_sign_out: 'Sign out',
    svy_back_to_homepage: 'Back to homepage',
    svy_display_button: 'Display',
    svy_status_not_submitted: 'Not submitted',
    svy_status_rescoring_required: 'Re-scoring required',
    svy_status_completed: 'Completed',
    svy_table_header_status: 'Status',
    svy_table_header_year_of_evaluation: 'Year of evaluation',
    svy_snackbar_error_default_text:
      'We could not complete your request. We are working on it. Please try again later.',
    svy_snackbar_error_uploaded_images_not_available:
      'Uploaded images are currently not available.',
    svy_snackbar_error_header: 'Something went wrong',
    svy_snackbar_success_header: 'Success',
    svy_snackbar_error_user_has_no_customers:
      'No customers matching this user. You were automatically logged out.',
    svy_snackbar_error_upload_invalid_file_type:
      'Invalid file type. Only images are allowed.',
    svy_snackbar_error_upload_too_many_files:
      'Too many files. Please upload images one by one.',
    svy_snackbar_error_upload_image_too_large:
      'Image is too large. Max size is 10 MB.',
    svy_tooltip_back_to_frontpage: 'Back to frontpage',
    svy_tooltip_customer_name: 'Customer Name',
    svy_tooltip_accept_terms_first: 'Accept the terms first',
    svy_tooltip_question_see_more_info: 'Click to see more info',
    svy_tooltip_question_choose_answer_first: 'Choose an answer first',
  },
  richTextLabels: {
    svy_welcome_text_on_homepage:
      '<p><strong>*In&nbsp;Danish*&nbsp;Welcome to the LEGO functional discount self-assessment\n</strong><br><br>From this site you will be able to perform a self-assessment and upload necessary justification linked to functional discount. The purpose of this self-assessment is to determine your functional discount for next year.&nbsp;<br><br>The assessment is based on your actual activities within the last 12 months and the impact of the assessment will be January 1st next year. The maximum discount to be obtained is 13%. <br><br>The self-assessment is completed by answering a questionnaire and uploading the required justification. The questionnaire is based on questions such as "Yes / No" or simple "Multiple choice". The justification required for individual questions are clarified with "Optional" or "Mandatory" guidance and include written descriptions of your activities and images for further elaboration.\n<br><br>In case of questions or if you would like to perform a re-scoring please reach out to LEGOBUCONTACT@LEGO.COM\n<br><br>Note: You are able to see previous performance in the overview below.\n<br><br>Best regards,\n<br><strong>The LEGO Group</strong></p>',
    svy_intro_policies:
      'By starting this survey, I hereby consent to the <a href="https://www.lego.com/en-us/legal/notices-and-policies/privacy-policy" target="_blank">privacy policy</a> and agree to its terms.',
    svy_loginpage_privacy_cookie_policy_line:
      'Please refer to our <a href="http://www.lego.com/en-us/legal/legal-notice/privacy-policy-full" tabindex="-1" target="_blank">Privacy Policy</a> & <a href="http://www.lego.com/en-us/legal/cookieinfo" tabindex="-1" target="_blank">Cookie Policy</a>',
    svy_footer_text:
      '<p>LEGO Company Limited, 33 Bath Road, Slough, Berks SL1 3UF, UK.</p>\n<p>LEGO and the LEGO logo are trademarks and copyrights of the LEGO Group.<br>\n©2020 The LEGO Group. All rights reserved. Use of this site signifies your agreement to the terms of use.</p>\n',
    svy_footer_privacy_policy_link:
      '<a href="https://www.lego.com/en-gb/legal/legal-notice/privacy-policy-full" tabindex="-1" target="_blank">Privacy policy</a>',
    svy_footer_legal_notice_link:
      '<a href="https://www.lego.com/en-gb/legal/legal-notice" tabindex="-1" target="_blank">Legal notice</a>',
    svy_upload_text_in_box:
      'Drag and drop image files here,<br>or click to select from disk.',
  },
  getPublicLabels: {
    svy_top_logo: 'LEGO® Functional Discount Self-Assessment',
    svy_loginpage_welcome_text:
      'Welcome to the LEGO® Functional Discount Self-Assessment',
    svy_sign_in_button: 'Sign in',
    svy_snackbar_error_header: 'Something went wrong',
    svy_snackbar_success_header: 'Success',
    svy_snackbar_error_user_has_no_customers:
      'No customers matching this user. You were automatically logged out.',
  },
  getPublicRichTextLabels: {
    svy_loginpage_privacy_cookie_policy_line:
      'Please refer to our <a href="http://www.lego.com/en-us/legal/legal-notice/privacy-policy-full" tabindex="-1" target="_blank">Privacy Policy</a> & <a href="http://www.lego.com/en-us/legal/cookieinfo" tabindex="-1" target="_blank">Cookie Policy</a>',
  },
};
