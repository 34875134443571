import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { spacing } from '../../config';
import { UploadedImageThumbnail } from './UploadedImageThumbnail';

const listWrapperStyle = css({
  marginTop: spacing.size4,
  display: 'flex',
});

const UploadedImageThumbnailList = ({
  uploadImageKeys,
  imageClickHandler,
  imageDeleteClickHandler = () => {},
  isEditable = true,
}) => (
  <div css={listWrapperStyle}>
    {uploadImageKeys.map((imageId) => (
      <UploadedImageThumbnail
        key={imageId}
        imageId={imageId}
        isEditable={isEditable}
        imageClickHandler={() => imageClickHandler(imageId)}
        imageDeleteClickHandler={() => imageDeleteClickHandler(imageId)}
      />
    ))}
  </div>
);

UploadedImageThumbnailList.propTypes = {
  uploadImageKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageClickHandler: PropTypes.func.isRequired,
  imageDeleteClickHandler: PropTypes.func,
  isEditable: PropTypes.bool,
};

export { UploadedImageThumbnailList };
