import PropTypes from 'prop-types';
import { Attach } from '@lego/klik-icons-react';
import { GenericConfirmationModal } from './GenericConfirmationModal';
import { useLabels } from '../../../providers/LabelsProvider';

const MissingProofWarningModal = ({ onClose, onConfirm, onExitHandler }) => {
  const {
    labels: { svy_attachment_missing, svy_end_edit, svy_cancel_button },
    richTextLabels: { svy_attachment_missing_explanation_prompt },
  } = useLabels();

  return (
    <GenericConfirmationModal
      status="warning"
      header={svy_attachment_missing}
      onConfirmText={svy_end_edit}
      onCloseText={svy_cancel_button}
      body={svy_attachment_missing_explanation_prompt}
      onClose={onClose}
      onConfirm={onConfirm}
      onExitHandler={onExitHandler}
      icon={<Attach />}
    />
  );
};

MissingProofWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onExitHandler: PropTypes.func,
};

export { MissingProofWarningModal };
