import { useQuery, useMutation } from '@apollo/client';
import { Link, useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { Button } from '@lego/klik-react';
import { Step, Stepper } from './stepper';
import { OverlayPortal } from './OverlayPortal';
import { Spinner } from './spinner/Spinner';
import { font, spacing, media, colors } from '../config';
import { useLabels } from '../providers/LabelsProvider';
import { useUser } from '../providers/UserProvider';
import { GET_COMPLETE_PAGE_DATA, FINISH_SURVEY } from '../queries/survey';
import { routes } from './RouteHandler';
import { Heading } from './Heading';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';
import { throwErrorWithTransactionName } from '../utils/elasticApmHeler';
import { withTransaction } from '@elastic/apm-rum-react';
import { useState } from 'react';
import {
  adjustAssessmentsBasedOnFeatureFlags,
  enhanceQuestionsWithAnswersAndProof,
  isAnyQuestionMissingProof,
} from './survey/helpers';
import { MissingProofWarningModal } from './question/modals/MissingProofWarningModal';
import { MissingProofExplainer } from './MissingProofExplainer';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
});

const scoreWrapperStyle = css({
  marginTop: spacing.size4,
});

const missingProofWrapperStyle = css({
  marginTop: spacing.size8,
});

const scoreTextStyle = css({
  fontSize: font.size.size05,
  marginBottom: spacing.size4,
});

const scoreStyle = css({
  fontSize: font.size.size3,
  fontWeight: font.weight.bold,
  color: colors.boldColors.lightBlue.base,
  backgroundColor: colors.boldColors.lightBlue.ligthBlue10,
  display: 'inline',
  borderRadius: 100,
  padding: `${spacing.size2}px ${spacing.size4}px`,
});

const buttonWrapperStyle = css({
  width: 'auto',
  display: 'flex',
  marginTop: spacing.size16,
  [media.medium]: {
    width: '63%',
  },
});

const firstButtonStyle = css({
  marginRight: spacing.size8,
});

const Complete = () => {
  const { year, version } = useParams();
  const history = useHistory();
  const { staticUserVariables } = useUser();

  const [isProofWarningModalVisible, setIsProofWarningModalVisible] =
    useState(false);

  const {
    labels: {
      svy_end_headline,
      svy_end_review,
      svy_end_finish,
      svy_back_to_homepage,
      svy_complete_page_total_discount_text,
      svy_snackbar_error_header,
      svy_snackbar_error_default_text,
    },
  } = useLabels();

  const {
    data: getCompletePageData,
    loading: getCompletePageDataLoading,
    error: getCompletePageDataError,
  } = useQuery(GET_COMPLETE_PAGE_DATA, {
    variables: staticUserVariables({ year, version }),
    fetchPolicy: 'network-only',
  });

  const [finishSurvey, { loading: finishSurveyLoading }] = useMutation(
    FINISH_SURVEY,
    {
      onCompleted: handleFinishSurveyResponse,
    }
  );

  if (getCompletePageDataLoading || finishSurveyLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  async function submitSurvey() {
    try {
      await finishSurvey({
        variables: staticUserVariables({ year, version }),
      });
    } catch (error) {
      generalApiErrorHandler({
        error,
        snackbarHeader: svy_snackbar_error_header,
        snackbarMessage: svy_snackbar_error_default_text,
      });
    }
  }

  async function handleFinishSurvey() {
    if (isAnyQuestionMissingProof(enhancedQuestions)) {
      setIsProofWarningModalVisible(true);
      return;
    }

    await submitSurvey();
  }

  function handleFinishSurveyResponse(response) {
    if (response.finishSurvey) history.push(`/${routes.confirmation}`);
  }

  // Error boundary catches this error
  if (getCompletePageDataError)
    throwErrorWithTransactionName(getCompletePageDataError, 'CompletePage');

  const {
    getSurvey: questions,
    getAnswers: answers,
    getSurveyState: surveyState,
  } = getCompletePageData;
  const areAllAnswersComplete = answers.every((answer) => answer.isComplete);

  const surveyDetailsForFeatureFlag = {
    year: Number(year),
  };

  let enhancedQuestions = enhanceQuestionsWithAnswersAndProof(
    questions,
    answers
  );
  enhancedQuestions = adjustAssessmentsBasedOnFeatureFlags(
    enhancedQuestions,
    surveyDetailsForFeatureFlag
  );

  return (
    <div css={containerStyle}>
      {isProofWarningModalVisible && (
        <MissingProofWarningModal
          onClose={() => {
            setIsProofWarningModalVisible(false);
          }}
          onConfirm={() => {
            setIsProofWarningModalVisible(false);
            history.push(`/${routes.survey}/${year}/${version}?mode=edit`);
          }}
        />
      )}
      <Heading type="h1" text={svy_end_headline} />
      <Stepper>
        {enhancedQuestions.map((question) => {
          return (
            <Step
              stepId={question.questionId}
              key={question.questionId}
              header={question.headline}
              isValid={true}
              isWarning={question.isMissingProof}
              isComplete={true}
            />
          );
        })}
      </Stepper>
      <div css={scoreWrapperStyle}>
        <p css={scoreTextStyle}>{svy_complete_page_total_discount_text}</p>
        <div css={scoreStyle}>{`${surveyState.score}%`}</div>
      </div>
      <div css={missingProofWrapperStyle}>
        {isAnyQuestionMissingProof(enhancedQuestions) && (
          <MissingProofExplainer />
        )}
      </div>
      <div css={buttonWrapperStyle}>
        <div css={firstButtonStyle}>
          <Link to={`/${routes.review}/${year}/${version}`}>
            <Button
              data-transaction-name="Review Survey"
              label={svy_end_review}
              variant="outline"
              css={firstButtonStyle}
            />
          </Link>
        </div>
        {areAllAnswersComplete && surveyState.isEditable ? (
          <Button
            data-transaction-name="Finish Survey"
            label={svy_end_finish}
            onClick={handleFinishSurvey}
          />
        ) : (
          <Link to={routes.homepage}>
            <Button
              data-transaction-name="Back to Homepage"
              label={svy_back_to_homepage}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

const CompletePage = withTransaction('CompletePage', 'component')(Complete);
export { CompletePage };
