export const awsConfig = {
  Auth: {
    region: process.env.AWS_REGION,
    identityPoolId: process.env.IDENTITY_POOL_ID,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: process.env.COOKIE_DOMAIN,
      secure: false,
      expires: 7,
    },
    oauth: {
      domain: process.env.OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.LOGIN_APP_URL,
      redirectSignOut: process.env.LOGIN_APP_URL,
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.S3BUCKET,
      region: process.env.AWS_REGION,
      level: 'public',
    },
  },
};
