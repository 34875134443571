import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { S3Image } from 'aws-amplify-react';
import { colors, spacing } from '../../config';
import { Button } from '@lego/klik-react';
import { ExpandBold, Delete } from '@lego/klik-icons-react';

const uploadImageExpandOverlayStyle = css({
  display: 'none',
  position: 'absolute',
  background: colors.neutralColors.white,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  opacity: 0.8,
});

const uploadImageContainerStyle = css({
  backgroundColor: colors.neutralColors.slate10,
  position: 'relative',
  borderRadius: 4,
  overflow: 'hidden',
});

const uploadImageExpandIcon = css({
  color: colors.neutralColors.slate50,
  width: 22,
  height: 22,
});

const uploadImageWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: spacing.size4,
  '&:last-child': {
    marginRight: 0,
  },
});

const uploadImageStyle = {
  display: 'block',
  width: 77,
  height: 50,
  cursor: 'pointer',
  borderRadius: 4,
  objectFit: 'contain',
};

const UploadedImageThumbnail = ({
  imageId,
  imageClickHandler,
  imageDeleteClickHandler,
  isEditable = true,
}) => (
  <div css={uploadImageWrapperStyle}>
    <div css={uploadImageContainerStyle} onClick={imageClickHandler}>
      <div css={uploadImageExpandOverlayStyle} data-css="hover-overlay">
        <ExpandBold css={uploadImageExpandIcon} />
      </div>
      <S3Image css={uploadImageStyle} level="public" imgKey={imageId} />
    </div>
    {isEditable && (
      <Button
        data-transaction-name="Delete image"
        aria-label="Delete image bin icon button"
        icon={<Delete />}
        variant="ghost"
        onClick={imageDeleteClickHandler}
      />
    )}
  </div>
);

UploadedImageThumbnail.propTypes = {
  imageId: PropTypes.string.isRequired,
  imageClickHandler: PropTypes.func.isRequired,
  imageDeleteClickHandler: PropTypes.func,
  isEditable: PropTypes.bool,
};

export { UploadedImageThumbnail };
