import { useState, useEffect, useContext, createContext, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_USER_INFO } from '../queries/user';
import { logger } from '../utils/logger';
import { cookieBannerLocalStorageKeyPrefix } from '../components/hompage/HomePage';
import { labelTranslationsFallback } from '../utils/labelTranslationsFallback';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';
import {
  addCustomBreadcrumb,
  addDefaultBreadcrumb,
  throwErrorWithTransactionName,
} from '../utils/elasticApmHeler';
import { apmBase } from '@elastic/apm-rum';

const UserContext = createContext();
export const localStorageCustomerIdKey = 'ci';
export const localStorageCustomerNameKey = 'cn';
export const localStorageLegoContactEmailKey = 'lce';

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [selectedCustomer, updateSelectedCustomer] = useState({
    id: window.localStorage.getItem(localStorageCustomerIdKey) || null,
    name: window.localStorage.getItem(localStorageCustomerNameKey) || null,
    hasAcceptedTerms: null,
    isLoading: true,
    legoContactEmail: '',
  });

  const didInit = useRef(false);

  async function signOut() {
    addDefaultBreadcrumb('User signing out', 'UserProvder.signOut');
    try {
      await Auth.signOut();
    } catch (error) {
      const { svy_snackbar_error_header, svy_snackbar_error_default_text } =
        labelTranslationsFallback;
      generalApiErrorHandler({
        error,
        snackbarHeader: svy_snackbar_error_header,
        snackbarMessage: svy_snackbar_error_default_text,
      });
    }
  }

  useEffect(() => {
    async function getUser() {
      let user = {};
      try {
        user = await Auth.currentAuthenticatedUser();
        addDefaultBreadcrumb('Current user confirmed', 'UserProvider.getUser');
        setUser(user);
      } catch (error) {
        addDefaultBreadcrumb('Failed to set user', 'UserProvider.getUser');
        const customerId = window.localStorage.getItem(
          localStorageCustomerIdKey
        );
        window.localStorage.removeItem(
          `${cookieBannerLocalStorageKeyPrefix}${customerId}`
        );
        window.localStorage.removeItem(localStorageCustomerIdKey);
        window.localStorage.removeItem(localStorageCustomerNameKey);
        window.localStorage.removeItem(localStorageLegoContactEmailKey);
        if (error !== 'The user is not authenticated') {
          logger.logError(error);
        }
      }
      if (!didInit.current) {
        didInit.current = true;
      }
      if (isUserLoading) setIsUserLoading(false);
      if (selectedCustomer.isLoading) {
        updateSelectedCustomer((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }
    if (!user && !didInit.current) {
      getUser();
    }
  }, [user, isUserLoading, selectedCustomer]);

  const { loading: getUserInfoLoading, error: getUserInfoError } = useQuery(
    GET_USER_INFO,
    {
      variables: {
        customerId: selectedCustomer.id,
        year: new Date().getFullYear(),
        version: 1,
      },
      skip: isUserLoading || !user || !selectedCustomer.id,
      fetchPolicy: 'network-only',
      onCompleted: handleTermsStateResponse,
    }
  );

  function handleTermsStateResponse(response) {
    addDefaultBreadcrumb(
      'User information query completed',
      'UserProvider.handleTermsStateResponse'
    );

    window.localStorage.setItem(
      localStorageLegoContactEmailKey,
      response.getCustomerAssignments.perform
    );
    updateSelectedCustomer((prevState) => ({
      ...prevState,
      hasAcceptedTerms: response.getTermsState?.accepted || false,
      isLoading: false,
      legoContactEmail: response.getCustomerAssignments.perform || '',
    }));
  }

  function handleSelectedCustomerUpdate(updateObject) {
    if (Object.prototype.hasOwnProperty.call(updateObject, 'id')) {
      addCustomBreadcrumb({
        message: 'Updating selected customer',
        category: 'UserProvider.handleSelectedCustomerUpdate',
        data: { id: updateObject.id },
      });
      window.localStorage.setItem(localStorageCustomerIdKey, updateObject.id);
      window.localStorage.setItem(
        localStorageCustomerNameKey,
        updateObject.name
      );
    }

    updateSelectedCustomer((prevState) => ({
      ...prevState,
      ...updateObject,
    }));
  }

  if (getUserInfoError) {
    window.localStorage.removeItem(localStorageCustomerIdKey);
    window.localStorage.removeItem(localStorageCustomerNameKey);
    window.localStorage.removeItem(localStorageLegoContactEmailKey);
    throwErrorWithTransactionName(getUserInfoError, 'UserProvider');
  }

  function staticUserVariables(additionalVariables) {
    const staticVariablesObject = {
      customerId: selectedCustomer.id,
    };

    return {
      ...staticVariablesObject,
      ...additionalVariables,
    };
  }

  if (user) {
    apmBase?.setUserContext({
      id: user?.id || '',
      username: user.username,
    });
    apmBase?.setCustomContext({
      customerId: selectedCustomer.id,
      customerName: selectedCustomer.name,
    });
  }

  return (
    <UserContext.Provider
      value={{
        isUserLoading:
          isUserLoading || getUserInfoLoading || selectedCustomer.isLoading,
        cognitoData: user || {},
        isLoggedIn: !!user,
        selectedCustomer,
        handleSelectedCustomerUpdate,
        staticUserVariables,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export { UserProvider, UserContext, useUser };
