import { gql } from '@apollo/client';

const GET_SURVEY_PAGE = gql`
  query GetSurveyPage($customerId: String!, $year: Int!, $version: Int!) {
    getAnswers(customerId: $customerId, year: $year, version: $version) {
      questionId
      isValid
      isComplete
      selectedAnswer
      comment
      images
    }
    getSurvey(customerId: $customerId, year: $year, version: $version) {
      surveyId
      questionId
      headline
      subHeader
      questionType
      conditions
      explanation
      answerOptions {
        answerId
        text
        score
      }
      answersExemptFromAttachments {
        answerId
      }
      furtherDetails
      commentsInTextBox
      uploadHeadline
    }
    getSurveyInfo(customerId: $customerId, year: $year, version: $version) {
      uploadId
      surveyState {
        isComplete
        isEditable
        score
      }
    }
  }
`;

const GET_SURVEY_LIST = gql`
  query GetSurveyList($customerId: String!) {
    getSurveyList(customerId: $customerId) {
      year
      version
      uploadId
      surveyState {
        isComplete
        isEditable
        status
      }
    }
  }
`;

const GET_COMPLETE_PAGE_DATA = gql`
  query getCompletePageData($customerId: String!, $year: Int!, $version: Int!) {
    getSurvey(customerId: $customerId, year: $year, version: $version) {
      headline
      questionId
      questionType
      answersExemptFromAttachments {
        answerId
      }
    }
    getAnswers(customerId: $customerId, year: $year, version: $version) {
      questionId
      isComplete
      selectedAnswer
      images
    }
    getSurveyState(customerId: $customerId, year: $year, version: $version) {
      isComplete
      isEditable
      score
    }
  }
`;

const UPDATE_ANSWER = gql`
  mutation UpdateAnswer(
    $customerId: String!
    $answer: AnswerInput!
    $year: Int!
    $version: Int!
  ) {
    updateAnswer(
      customerId: $customerId
      answer: $answer
      year: $year
      version: $version
    ) {
      questionId
    }
  }
`;

const UPDATE_ALL_ANSWERS = gql`
  mutation UpdateAllAnswers(
    $customerId: String!
    $answers: [AnswerInput!]!
    $year: Int!
    $version: Int!
  ) {
    updateAllAnswers(
      customerId: $customerId
      answers: $answers
      year: $year
      version: $version
    )
  }
`;

const FINISH_SURVEY = gql`
  mutation FinishSurvey($customerId: String!, $year: Int!, $version: Int!) {
    finishSurvey(customerId: $customerId, year: $year, version: $version)
  }
`;

const RESET = gql`
  mutation Reset($customerId: String!, $year: Int!, $version: Int!) {
    reset(customerId: $customerId, year: $year, version: $version)
  }
`;

export {
  GET_SURVEY_PAGE,
  GET_SURVEY_LIST,
  GET_COMPLETE_PAGE_DATA,
  UPDATE_ANSWER,
  UPDATE_ALL_ANSWERS,
  FINISH_SURVEY,
  RESET,
};
