import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useLabels } from '../../providers/LabelsProvider';
import { colors, spacing } from '../../config';
import { surveyStatus } from '../../constants';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const colorStyle = (color) =>
  css({
    display: 'block',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: color,
    marginRight: spacing.size2,
  });

const AssessmentStatus = ({ status }) => {
  const {
    labels: {
      svy_status_not_submitted,
      svy_status_changes_required,
      svy_status_rescoring_required,
      svy_status_in_lego_review,
      svy_status_completed,
    },
  } = useLabels();

  const assessmentStatuses = {
    [surveyStatus.NOT_SUBMITTED]: {
      text: svy_status_not_submitted,
      color: colors.accentColors.gold.base,
    },
    [surveyStatus.CHANGES_REQUIRED]: {
      text: svy_status_changes_required,
      color: colors.utilityColors.error.error500,
    },
    [surveyStatus.RESCORING_REQUIRED]: {
      text: svy_status_rescoring_required,
      color: colors.accentColors.gold.base,
    },
    [surveyStatus.IN_LEGO_REVIEW]: {
      text: svy_status_in_lego_review,
      color: colors.neutralColors.slate40,
    },
    [surveyStatus.COMPLETED]: {
      text: svy_status_completed,
      color: colors.utilityColors.success.dark,
    },
  };

  const { text, color } = assessmentStatuses[status];

  return (
    <div css={wrapperStyle}>
      <span css={colorStyle(color)}></span>
      <span>{text}</span>
    </div>
  );
};

AssessmentStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export { AssessmentStatus };
