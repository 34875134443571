import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { media, colors, spacing } from '../config';
import { Header } from '../components/Header';
import { Heading } from '../components/Heading';
import hero from '../assets/hero_minifig.png';

const backgroundStyle = css({
  width: '100%',
  height: 290,
  paddingLeft: spacing.size4,
  paddingRight: spacing.size4,
  background: `linear-gradient(${colors.boldColors.lightBlue.lightBlue100}, ${colors.boldColors.lightBlue.lightBlue90})`,
  [media.medium]: {
    height: 394,
  },
});

const containerStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const signInContainerStyle = css({
  width: '100%',
  [media.medium]: {
    width: 580,
  },
});

const signInBoxStyle = css({
  background: colors.neutralColors.white,
  paddingTop: spacing.size8,
  paddingRight: spacing.size4,
  paddingBottom: spacing.size6,
  paddingLeft: spacing.size4,
  borderRadius: 8,
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)',
});

const titleStyle = css({
  color: colors.neutralColors.white,
  paddingTop: spacing.size2,
  marginBottom: spacing.size6,
  marginTop: 130,
  [media.medium]: {
    marginBottom: spacing.size16,
    marginTop: 160,
  },
});

const heroStyle = css({
  display: 'none',
  marginTop: 125,
  marginLeft: 80,
  [media.large]: {
    display: 'block',
  },
});

const LoginLayout = ({ title = '', footer, showImage = false, children }) => {
  return (
    <div>
      <Header />
      <div css={backgroundStyle}>
        <div css={containerStyle}>
          <div css={signInContainerStyle}>
            <Heading type="h1" additionalStyles={titleStyle} text={title} />
            <div css={signInBoxStyle}>{children}</div>
            {footer}
          </div>
          {showImage && (
            <img css={heroStyle} src={hero} alt="Lego hero figure" />
          )}
        </div>
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.element,
  showImage: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export { LoginLayout };
