export const colors = {
  neutralColors: {
    white: '#ffffff',
    black: '#141414',
    slate02: '#fcfcfc',
    slate05: '#f8f8f8',
    slate10: '#f1f1f1',
    slate20: '#e4e4e3',
    slate30: '#d6d6d5',
    slate40: '#c8c8c7',
    slate50: '#adadac',
    slate60: '#919190',
    slateBase: '#767674',
    slate90: '#474746',
    slate100: '#2f2f2e',
    slate900: '#292928',
  },
  boldColors: {
    lightBlue: {
      ligthBlue10: '#e9f2f8',
      ligthBlue20: '#d4e5f2',
      lightBlue30: '#bed7eb',
      lightBlue50: '#7db0d8',
      base: '#277bbe',
      lightBlue80: '#1f6298',
      lightBlue90: '#174a72',
      lightBlue100: '#10314c',
    },
    darkBlue: {
      base: '#0248a4',
      darkBlue80: '#013a87',
    },
  },
  accentColors: {
    gold: {
      base: '#fdb100',
    },
  },
  utilityColors: {
    information: {
      base: '#009bd7',
      information400: '#179FE3',
      information50: '#E6F5FC',
    },
    success: {
      base: '#22a317',
      dark: '#138b09',
      success400: '#1CB20C',
    },
    warning: {
      base: '#ffc900',
      warning100: '#ffc90096',
      warning400: '#F2A705',
    },
    error: {
      base: '#e3000b',
      error500: '#DB1F1F',
      error600: '#B20707',
    },
  },
};
