import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Cross } from '@lego/klik-icons-react';
import { Button } from '@lego/klik-react';
import { spacing, media, colors, font } from '../config';

const bannerContainerStyle = (position) =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    position: position === 'top' ? 'absolute' : 'fixed',
    left: spacing.size4,
    right: spacing.size4,
    bottom: position === 'bottom' ? spacing.size4 : 'auto',
    top: position === 'top' ? 108 : 'auto',
    border: `1px solid ${colors.utilityColors.information.information400}`,
    borderLeftWidth: 12,
    backgroundColor: colors.utilityColors.information.information50,
    borderRadius: 2,
    padding: spacing.size4,
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.1)',
    [media.medium]: {
      left: spacing.size12,
      right: spacing.size12,
      bottom: position === 'bottom' ? spacing.size12 : 'auto',
      top: position === 'top' ? 96 : 'auto',
    },
  });

const leftContainerStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
});

const iconStyle = css({
  marginRight: spacing.size3,
});

const textWrapperStyle = css({
  marginRight: spacing.size2,
});

const headerTextStyle = css({
  fontWeight: font.weight.medium,
});

const bodyTextStyle = css({
  fontSize: font.size.sizeM1,
});

const buttonWrapperStyle = css({
  flexShrink: 0,
  marginTop: -spacing.size2,
});

const Banner = ({ icon, header, text, position = 'bottom', onClose }) => {
  return (
    <div css={bannerContainerStyle(position)} role="banner">
      <span css={leftContainerStyle}>
        <img src={icon} css={iconStyle} />
        <div css={textWrapperStyle}>
          <h4 css={headerTextStyle}>{header}</h4>
          <span css={bodyTextStyle}>{text}</span>
        </div>
      </span>
      <span css={buttonWrapperStyle}>
        <Button
          data-transaction-name="Close banner button"
          aria-label="Close banner button"
          icon={<Cross />}
          variant="ghost"
          size="small"
          onClick={onClose}
        />
      </span>
    </div>
  );
};

Banner.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  onClose: PropTypes.func,
};

export { Banner };
