import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { useUser } from '../providers/UserProvider';
import { font, colors, spacing } from '../config';
import { PUBLIC_LABELS } from '../queries/labels';
import { labelsReducer } from '../providers/LabelsProvider';
import { OverlayPortal } from './OverlayPortal';
import { Spinner } from './spinner/Spinner';
import { routes } from './RouteHandler';
import { labelTranslationsFallback } from '../utils/labelTranslationsFallback';
import { LoginLayout } from '../layouts/LoginLayout';
import { userHasNoCustomersKey } from '../constants/localStorageKeys';
import { addSnackbar, snackbarTypes } from '../utils/snackbar';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';

const buttonStyle = css({
  width: '100%',
  color: colors.neutralColors.white,
  fontSize: font.size.medium,
  background: colors.boldColors.lightBlue.base,
  paddingTop: spacing.size4,
  paddingBottom: spacing.size4,
  marginBottom: spacing.size2,
  borderRadius: 4,
  border: 0,
  cursor: 'pointer',
  boxShadow: '0px 1px 0px rgba(1,4,16, .1), 0px -1px 0px rgba(0,0,0,.25) inset',
  '&:hover': {
    background: colors.boldColors.lightBlue.lightBlue80,
  },
});

const privacyCookieTextStyle = css({
  color: colors.neutralColors.slate90,
  fontSize: font.size.sizeM1,
  marginTop: spacing.size4,
  display: 'block',
});

const LoginPage = () => {
  const { isLoggedIn, selectedCustomer } = useUser();
  const userHasNoCustomers =
    window.localStorage.getItem(userHasNoCustomersKey) || false;

  const {
    data: publicLabelsData,
    loading: publicLabelsLoading,
    error: publicLabelsError,
  } = useQuery(PUBLIC_LABELS);

  if (publicLabelsLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  let labels = labelsReducer(publicLabelsData);

  if (publicLabelsError) {
    const { svy_snackbar_error_header } = labelTranslationsFallback;
    generalApiErrorHandler({
      error: publicLabelsError,
      snackbarHeader: svy_snackbar_error_header,
      snackbarMessage: 'Only English labels are currently available',
    });
    labels = labelTranslationsFallback;
  }

  const {
    getPublicLabels: {
      svy_loginpage_welcome_text,
      svy_sign_in_button,
      svy_snackbar_error_header,
      svy_snackbar_error_user_has_no_customers,
    },
    getPublicRichTextLabels: { svy_loginpage_privacy_cookie_policy_line },
  } = labels;

  if (isLoggedIn && !selectedCustomer.id) {
    return <Redirect to={`/${routes.customerLogin}`} />;
  }

  if (isLoggedIn && !selectedCustomer.hasAcceptedTerms) {
    return <Redirect to={`/${routes.terms}`} />;
  }

  if (isLoggedIn && selectedCustomer.id) {
    return <Redirect to={`/${routes.homepage}`} />;
  }

  if (JSON.parse(userHasNoCustomers)) {
    addSnackbar({
      header: svy_snackbar_error_header,
      text: svy_snackbar_error_user_has_no_customers,
      type: snackbarTypes.ERROR,
    });
    window.localStorage.removeItem(userHasNoCustomersKey);
  }

  const LoginFooter = () => (
    <span
      css={privacyCookieTextStyle}
      dangerouslySetInnerHTML={{
        __html: svy_loginpage_privacy_cookie_policy_line,
      }}
    />
  );

  return (
    <LoginLayout
      showImage={true}
      title={svy_loginpage_welcome_text}
      footer={<LoginFooter />}
    >
      <button
        data-transaction-name="Sign in"
        aria-label="Sign in"
        css={buttonStyle}
        onClick={() => Auth.federatedSignIn()}
      >
        {svy_sign_in_button}
      </button>
    </LoginLayout>
  );
};

export { LoginPage };
