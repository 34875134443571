import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useLabels } from '../../providers/LabelsProvider';
import { useUser } from '../../providers/UserProvider';
import { spacing } from '../../config';
import { OverlayPortal } from '../OverlayPortal';
import { Spinner } from '../spinner/Spinner';
import { GET_SURVEY_LIST } from '../../queries/survey';
import { HomePageTable } from './HomePageTable';
import { Banner } from '../Banner';
import legoCookieSvg from '../../assets/lego-cookie.svg';
import { Heading } from '../Heading';
import { replaceContactEmail } from './helpers';
import { throwErrorWithTransactionName } from '../../utils/elasticApmHeler';

const contactEmailPlaceholder = 'LEGOBUCONTACT@LEGO.COM';
export const cookieBannerLocalStorageKeyPrefix = 'cookieBannerSeen';

const contentWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 554,
  margin: '0 auto',
});

const bodyTextStyle = css({
  marginTop: spacing.size4,
  marginBottom: spacing.size10,
});

const HomePage = () => {
  const {
    labelsLoading,
    labels: {
      svy_intro_headline,
      svy_cookie_banner_header,
      svy_cookie_banner_body_text,
    },
    richTextLabels: { svy_welcome_text_on_homepage },
  } = useLabels();
  const { staticUserVariables, selectedCustomer } = useUser();

  const hasCookieBannerBeenSeen = localStorage.getItem(
    `${cookieBannerLocalStorageKeyPrefix}${selectedCustomer.id}`
  );
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(
    !hasCookieBannerBeenSeen
  );

  const {
    data: surveyListData,
    loading: isSurveyListLoading,
    error: surveyListError,
  } = useQuery(GET_SURVEY_LIST, {
    variables: staticUserVariables(),
    fetchPolicy: 'network-only',
  });

  const handleCookieBannerOnClose = () => {
    localStorage.setItem(
      `${cookieBannerLocalStorageKeyPrefix}${selectedCustomer.id}`,
      true
    );
    setIsCookieBannerVisible(false);
  };

  // Error boundary catches this error
  if (surveyListError)
    throwErrorWithTransactionName(surveyListError, 'HomePage');

  if (isSurveyListLoading || !surveyListData || labelsLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  return (
    <div css={contentWrapperStyle}>
      <Heading type="h1" text={svy_intro_headline} />
      <div
        css={bodyTextStyle}
        dangerouslySetInnerHTML={{
          __html: replaceContactEmail(
            svy_welcome_text_on_homepage,
            contactEmailPlaceholder,
            selectedCustomer.legoContactEmail
          ),
        }}
      />
      <HomePageTable surveyListdata={surveyListData.getSurveyList} />
      {isCookieBannerVisible && (
        <Banner
          aria-label="Cookie info banner"
          icon={legoCookieSvg}
          header={svy_cookie_banner_header}
          text={svy_cookie_banner_body_text}
          onClose={handleCookieBannerOnClose}
        />
      )}
    </div>
  );
};

export { HomePage };
