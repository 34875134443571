import { Switch, useLocation } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { OverlayPortal } from './OverlayPortal';
import { Spinner } from './spinner/Spinner';
import { AuthRoute } from './AuthRoute';
import { RootPage } from './RootPage';
import { TermsPage } from './TermsPage';
import { HomePage } from './hompage/HomePage';
import { SurveyPage } from './survey/SurveyPage';
import { LoginPage } from './LoginPage';
import { CompletePage } from './CompletePage';
import { ReviewPage } from './ReviewPage';
import { ConfirmationPage } from './ConfirmationPage';
import { PageNotFound } from './PageNotFound';
import { withMainLayout } from '../layouts/withMainLayout';
import { MultiCustomerLoginPage } from './MultiCustomerLoginPage';
import { ErrorPage } from './ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { ApmRoute } from '@elastic/apm-rum-react';

const devEnvs = ['development', 'staging'];
const isDevelopmentEnv = devEnvs.includes(process.env.MCP_ENV);

let Reset;
if (isDevelopmentEnv) {
  Reset = require('./Reset').Reset;
}

const routes = {
  login: 'login',
  customerLogin: 'customer-login',
  terms: 'terms',
  homepage: 'homepage',
  survey: 'survey',
  complete: 'complete',
  review: 'review',
  confirmation: 'confirmation',
  reset: 'reset',
};

const RouteHandler = () => {
  const location = useLocation();
  const { isUserLoading } = useUser();

  if (isUserLoading) {
    return (
      <OverlayPortal>
        <Spinner />
      </OverlayPortal>
    );
  }

  return (
    <ErrorBoundary key={location.pathname} fallback={<ErrorPage />}>
      <Switch>
        <ApmRoute exact path="/" component={RootPage} />
        <ApmRoute exact path={`/${routes.login}`} component={LoginPage} />
        <ApmRoute
          exact
          path={`/${routes.customerLogin}`}
          component={MultiCustomerLoginPage}
        />
        <ApmRoute exact path={`/${routes.terms}`} component={TermsPage} />
        <AuthRoute
          exact
          path={`/${routes.homepage}`}
          component={withMainLayout(HomePage)}
        />
        <AuthRoute
          exact
          path={`/${routes.survey}/:year/:version/`}
          component={withMainLayout(SurveyPage)}
        />
        <AuthRoute
          exact
          path={`/${routes.complete}/:year/:version/`}
          component={withMainLayout(CompletePage)}
        />
        <AuthRoute
          exact
          path={`/${routes.review}/:year/:version/`}
          component={withMainLayout(ReviewPage)}
        />
        <AuthRoute
          exact
          path={`/${routes.confirmation}`}
          component={withMainLayout(ConfirmationPage)}
        />
        {isDevelopmentEnv && (
          <AuthRoute
            exact
            path={`/${routes.reset}/:year/:version`}
            component={Reset}
          />
        )}
        <ApmRoute path="*" component={PageNotFound} />
      </Switch>
    </ErrorBoundary>
  );
};

export { RouteHandler, routes };
