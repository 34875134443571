import { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { useLabels } from '../providers/LabelsProvider';
import { Dropzone } from './Dropzone';
import { addSnackbar, snackbarTypes } from '../utils/snackbar';
import { imageUploadErrorTypes } from '../constants';
import { generalApiErrorHandler } from '../utils/generalApiErrorHandler';
import { logger } from '../utils/logger';

const ImageUpload = ({ onSuccess, isDisabled, questionId, uploadId }) => {
  const acceptedFiles = ['image/*'];
  const [uploadProgress, setUploadProgress] = useState(0);

  const {
    labels: {
      svy_snackbar_error_header,
      svy_snackbar_error_default_text,
      svy_snackbar_error_upload_invalid_file_type,
      svy_snackbar_error_upload_too_many_files,
      svy_snackbar_error_upload_image_too_large,
    },
  } = useLabels();

  const imageUploadErrorHandler = (errorsArray) => {
    if (!errorsArray.length || !errorsArray[0].errors.length) return false;

    const error = errorsArray[0].errors[0];
    let errorMessage;

    switch (error.code) {
      case imageUploadErrorTypes.FILE_INVALID_TYPE:
        errorMessage = svy_snackbar_error_upload_invalid_file_type;
        break;

      case imageUploadErrorTypes.TOO_MANY_FILES:
        errorMessage = svy_snackbar_error_upload_too_many_files;
        break;

      case imageUploadErrorTypes.FILE_TOO_LARGE:
        errorMessage = svy_snackbar_error_upload_image_too_large;
        break;

      default:
        errorMessage = svy_snackbar_error_default_text;
    }

    logger.logError(error);
    return addSnackbar({
      header: svy_snackbar_error_header,
      text: errorMessage,
      type: snackbarTypes.ERROR,
    });
  };

  const uploadToS3 = async (file) => {
    if (!file) return false;

    const uuid = uuidv4();
    const filePath = `${uploadId}/qid-${questionId}/${uuid}/${file.path}`;
    try {
      await Storage.put(filePath, file, {
        contentType: file.type,
        progressCallback(progress) {
          const uploadProgressInPercent = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setUploadProgress(uploadProgressInPercent);
        },
      });
      setUploadProgress(0);
      onSuccess(filePath);
    } catch (error) {
      generalApiErrorHandler({
        error,
        snackbarHeader: svy_snackbar_error_header,
        snackbarMessage: svy_snackbar_error_default_text,
      });
    }
  };

  return (
    <Dropzone
      acceptedFiles={acceptedFiles}
      uploadProgress={uploadProgress}
      onDrop={(acceptedFiles) => uploadToS3(acceptedFiles[0])}
      onDropRejected={imageUploadErrorHandler}
      isDisabled={isDisabled || !!uploadProgress}
    />
  );
};

ImageUpload.propTypes = {
  onSuccess: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  questionId: PropTypes.string.isRequired,
  uploadId: PropTypes.string.isRequired,
};

export { ImageUpload };
