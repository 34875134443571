import { apmBase } from '@elastic/apm-rum';

const throwErrorWithTransactionName = (error, transactionName) => {
  if (transactionName) {
    apmBase
      ?.getCurrentTransaction()
      ?.addLabels({ transaction: transactionName });
  }
  throw error;
};

const breadCrumbKey = 'Breadcrumb';

const addCustomBreadcrumb = ({ message, category = 'console', data = {} }) => {
  if (data && Object.keys(data).length > 0) {
    message = `${breadCrumbKey}: ${message} ${JSON.stringify(data)}`;
  } else {
    message = `${breadCrumbKey}: ${message}`;
  }

  apmBase?.getCurrentTransaction()?.startSpan(message, category);
};

const addDefaultBreadcrumb = (message, category = 'console') => {
  addCustomBreadcrumb({ message, category });
};

export {
  addCustomBreadcrumb,
  addDefaultBreadcrumb,
  throwErrorWithTransactionName,
};
